#standard-page {

  // header customization
  .page-header {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.0) 100%), url('/img/energia_header.png');
    background-position: left center, bottom center;
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    margin-bottom: 150px;

    h1 {
      margin-top: 130px;
      margin-bottom: 80px;
      font-size: 0.7*$size_h1;
      line-height: 0.7*1.5*$size_h1;
      text-align: center;
      @media #{$media_md} {
        font-size: $size_h1;
        line-height: 1.5*$size_h1;
        margin-top: 210px;
        margin-bottom: 160px;
        text-align: left;
      }
    }
  }

  h2 {
    margin-top: 0;
    color: #1f1f1f;
    margin-bottom: 50px;
    font-size: 2.875rem;
    line-height: 4.3125rem;
    font-family: Inter, sans-serif, Arial;
    font-weight: 500;
  }

  h4 {
    margin-bottom: 15px;
    color: #25406c;
    font-size: 1.75rem;
    line-height: 2.625rem;
    font-family: Inter, sans-serif, Arial;
    font-weight: 500;
  }

  p {
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: Inter, sans-serif, Arial;
    font-weight: 500;
    margin-bottom: 40px;
    color: #1f1f1f;
  }

}