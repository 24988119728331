.info-card {

  & * {
    // all elements should use black colour by default
    color: #1F1F1F;
  }

  .info-card-text {
    padding-left:0;
    padding-right:0;

    @media #{$media_xs} {
      padding-left:2rem;
      padding-right:4rem;
    }


    .info-card-text-title {
      font-size: 2.875rem;
      line-height: 1.1;
      font-family: "Inter", sans-serif, Arial;
      font-weight: 500;
      margin-bottom:2.5rem;
    }
    .info-card-text-subtitle {
      color: #d9bb80;
      display: block;
      font-size: .875rem;
      line-height: 1.3125rem;
      margin-top: 20px;
      font-family: Inter, sans-serif, Arial;
      font-weight: 700;
      margin-bottom: 2rem;
    }
    .info-card-text-content {

    }
  }

  .info-card-image {
    position:relative;
    display:flex;
    height: 100%;
    width:100%;
    height: 29rem;

    .info-card-image-bg {
      background-color:#F9F1E1;
      display: flex;
      position:relative;
      height: 80%;
      width:80%;

      img {
        position:relative;
        object-fit: none;
      }
    }


    &.left {
      flex-direction: column;
      justify-content: start;

      .info-card-image-bg {
        justify-content: end;
        width:70%;
        top:8%; // should be same as IMG Top but reverse (+ -> -)
        left:0;

        img {
          max-height: 100%;
          max-width:80%;
          top:-8%;
        }
      }
    }

    &.right {
      flex-direction: column;
      justify-content: start;

      .info-card-image-bg {
        justify-content: end;
        width:70%;
        top:8%; // should be same as IMG Top but reverse (+ -> -)
        left:0;
        align-self: end;

        img {
          max-height: 100%;
          max-width:80%;
          top:-8%;
        }
      }
    }

    &.middle {
      flex-direction: row;
      justify-content: center;

      .info-card-image-bg {
        justify-content: center;
        width:90%;
        top:20%; // should be same as IMG Top but reverse (+ -> -)
        left:0;

        img {
          max-height: 100%;
          max-width:80%;
          float:right;
          top:-20%;
        }
      }
    }
  }

}