.custom-carousel {

  position:relative;
  height: 200px;

  .custom-carousel-left, .custom-carousel-right {
    position:absolute;
    height: 200px;
    width:50px;
    top:0;
  }

  .custom-carousel-left {
    left:0;
    display:none;
  }

  .custom-carousel-right {
    right:0;
    display:none;
  }

  .custom-carousel-elements {
    height: inherit;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;

    .custom-carousel-element {
      height: inherit;
      flex-grow: 1;
      width:100%;
      opacity:1;
      transition: width 2s ease-in-out, height 1s ease-in-out, opacity 2s ease-in-out;

      .pad-fill {
        padding: 0.2rem 1rem;
        height: inherit;
        width:inherit;
        transition: padding 1s ease-in-out;

        img {
          height:100%;
          object-fit: cover;
          cursor:pointer;
        }
      }
    }
  }
}