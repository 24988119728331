$white  : #ffffff;
$black  : #000000;

$web_dark   : #1F1F1F;
$web_dark2  : #333333;
$web_gray   : #7d7d7d;
$web_gray2  : #C5C5C5;
$web_gray4  : #E7E7E7;
$web_blue   : #25406C;
$web_blue2  : #E3ECFD;
$web_gold   : #D9BB80;
$web_gold2  : #C0A267;
$web_beige  : #F9F1E1;
$web_beige2 : #EFE7D8;
$web_red    : #EC7171;
$web_red2   : #FFE3E3;

$detail_header_height: 500px;

$media_xxxs    : 'screen and (min-width: 360px)';
$media_xxs    : 'screen and (min-width: 440px)';
$media_xs    : 'screen and (min-width: 36.0em)'; // 576
$media_sm    : 'screen and (min-width: 48.0em)'; // 768
$media_md    : 'screen and (min-width: 62.0em)'; // 992
$media_lg    : 'screen and (min-width: 75.0em)'; // 1200
$media_llg   : 'screen and (min-width: 87.5em)'; // 1400
$media_xl    : 'screen and (min-width: 100.0em)'; // 1600
$media_xxl   : 'screen and (min-width: 120.0em)'; // 1920
$retina      : 'only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)';

$media_max_xs    : 'screen and (max-width: 35.99em)'; // 576
$media_max_sm    : 'screen and (max-width: 47.99em)'; // 768
$media_max_md    : 'screen and (max-width: 61.99em)'; // 991
$media_max_lg    : 'screen and (max-width: 74.99em)'; // 1199

$mobile_breakpoint : $media_md;

$size_def         : 0.625rem;
$size_micro       : 0.7*$size_def;
$size_text_mini   : 1.2*$size_def;
$size_menu_toggle : 1.4*$size_def;
$size_text_small  : 1.4*$size_def;
$size_h6          : 1.4*$size_def;
$size_text        : 1.6*$size_def;
$size_big         : 1.8*$size_def;
$size_h5          : 2.0*$size_def;
$size_text_big    : 2.0*$size_def;
$size_text_big2   : 2.6*$size_def;
$size_menu        : 2.6*$size_def;
$size_h4          : 2.8*$size_def;
$size_big2        : 2.8*$size_def;
$size_h3          : 3.4*$size_def;
$size_info        : 4.0*$size_def;
$size_price       : 4.0*$size_def;
$size_contact_l   : 4.0*$size_def;
$size_h2          : 4.6*$size_def;
$size_h1          : 5.8*$size_def;
$size_home        : 7.4*$size_def;


