#cookies-disclaimer {
  padding: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: rgba(31, 31, 31,.9);
  color: $white;
  max-height: 90vh;
  overflow: auto;
}

@media all and (min-width: 900px) {
  #cookies-disclaimer {
    padding: 20px 10%;
  }
}

#cookies-disclaimer h2.title, #cookies-disclaimer .title.h2 {
  color: #fff;
  font-size: 1.5rem;
  line-height: 1.5;
}

#cookies-disclaimer .more-link{
  text-decoration: underline;
}

#cookies-disclaimer h4, #cookies-disclaimer .h4 {
  color: #fff;
  font-size: 1.1rem;
}

#cookies-disclaimer .description {
  max-width: 100%;
}

#cookies-disclaimer .acceptance {
  margin-top: 15px;
}

#cookies-disclaimer .acceptance .cookies-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  text-decoration: none;
  margin-right: 15px;
  margin-bottom: 15px;
}

#cookies-disclaimer .acceptance .cookies-btn.acceptance-all {
  background: $web_gold2;
  color: $white;
}

#cookies-disclaimer .acceptance .cookies-btn.ghost-button {
  color: $white;
  border: 1px solid $white;
}

#cookies-disclaimer #cookies-disclaimer-advanced-settings {
  padding-top: 15px;
}

#cookies-disclaimer #cookies-disclaimer-advanced-settings .item {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

#cookies-disclaimer #cookies-disclaimer-advanced-settings .item .column {
  display: flex;
  flex-direction: column;
  padding-right: 15px;
}

#cookies-disclaimer .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

#cookies-disclaimer .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

#cookies-disclaimer .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

#cookies-disclaimer .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

#cookies-disclaimer input:checked + .slider {
  background-color: $web_gold2;
}

#cookies-disclaimer input:disabled + .slider {
  background-color: $web_gold2;
  opacity: 0.5;
}

#cookies-disclaimer input:focus + .slider {
  box-shadow: 0 0 1px $web_gold2;
}

#cookies-disclaimer input:checked + .slider:before {
  transform: translateX(26px);
}

#cookies-disclaimer .slider.round {
  border-radius: 34px;
}

#cookies-disclaimer .slider.round:before {
  border-radius: 50%;
}
