#investment-page {
  * {
    font-family: Inter, sans-serif, Arial;
  }

  // header customization
  .page-header {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.0) 100%), url('/img/investment/header_investicia.png');
    background-position: left center, bottom center;
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    margin-bottom: 150px;

    h1 {
      margin-top: 130px;
      margin-bottom: 80px;
      font-size: 0.7*$size_h1;
      line-height: 0.7*1.5*$size_h1;
      text-align: center;
      @media #{$media_md} {
        font-size: $size_h1;
        line-height: 1.5*$size_h1;
        margin-top: 210px;
        margin-bottom: 160px;
        text-align: left;
      }
    }
  }

  // info-card customization
  .info-card-text-subtitle {
    text-transform: uppercase;
  }

  // page specific

  h2.header, h3.header {
    font-family: Inter, sans-serif, Arial;
    font-weight: 500;
  }

  h2.header {

      color: #1f1f1f;
      font-size: 2.875rem;
      line-height: 4.3125rem;
  }

  h3.header {
      color: #1f1f1f;
      font-size: 1.75rem;
      line-height: 2.625rem;
  }

  // finance plan

  .finance-plan-header {

    span {
      color: #d9bb80;
      display: block;
      font-size: .875rem;
      line-height: 1.3125rem;
      font-family: Inter, sans-serif, Arial;
      font-weight: 700;
      text-transform: uppercase;
      padding: 2rem 0;
    }

    p {
      color:#1f1f1f;
    }
  }

  .finance-plan-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 3rem 2.5rem;

    &.card1 {
      background-color: #F9F1E1;
    }

    &.card2 {
      background-color: #E4EBF6;
    }

    .finance-plan-card-title {
      color:#25406c;
      font-size: 1.75rem;
      line-height: 2.625rem;
      font-family: Inter, sans-serif, Arial;
      font-weight: 500;
    }

    .finance-plan-card-spoiler {
      height: 2px;
      width: 10%;
      background-color: #d9bb80;
      margin: 2rem 0;
    }

    .finance-plan-card-content {

    }

  }

  // costs and revenues

  .core-card {

    .core-card-title {
      text-transform: uppercase;
      color: #d9bb80;
      font-family: Inter, sans-serif, Arial;
      font-weight: 500;

      display: inline-block;
      padding-bottom: 2rem;
    }

    .core-card-cost, .core-card-revenue {
      padding-left: 3rem;
      padding-bottom: 3rem;

      div {
        font-weight: bold;
        position:relative;
        &:before {
          color: transparent;
          background-color: transparent;
          position: absolute;
          left: -3rem;
          top: 0;
          width: 37px;
          height: 37px;
          content: "";
        }
      }

    }

    .core-card-cost {

      .cost1 {
        &:before {
          background: url(/img/investment/1.svg) center center no-repeat;
        }
      }

      .cost2 {
        &:before {
          background: url(/img/investment/2.svg) center center no-repeat;
        }
      }

      .cost3 {
        &:before {
          background: url(/img/investment/3.svg) center center no-repeat;
        }
      }
    }

    .core-card-revenue {

      .revenue1 {
        &:before {
          background: url(/img/investment/check.svg) center center no-repeat;
        }
      }
      .revenue2 {
        &:before {
          background: url(/img/investment/check.svg) center center no-repeat;
        }
      }
    }
  }

}