.free-parking {

  .container-fluid.header-about {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0, rgba(0, 0, 0, 0.0) 100%), url('/img/free-parking/hero.jpg');
  }

  &__content {
    margin-top: 12rem;

    h2 {
      color: $web_dark;
    }

    h3 {
      color: #D9BB80;
      font-size: 1rem;
      text-transform: uppercase;
      line-height: 1;
    }

    p {
      color: $web_dark;
    }
  }

  &__contatc-button {
    width: fit-content;
    min-width: 220px;
    padding: 28px 53px 28px 36px;
    position: relative;
    background: #D9BB80;
    color: #ffffff;
    border: 0;
    box-shadow: none;
    text-transform: uppercase;
    font-size: 0.875rem;
    line-height: 0.875rem;
    order: 2;
    margin-bottom: 0;
    font-family: "Inter", sans-serif, Arial;
    font-weight: 700;
    transition: background-color ease-in-out 150ms;
    display: inline-block;

    &:hover {
      background: #C0A267;
      color: #ffffff;
    }

    &:after {
      position: absolute;
      background: #C0A267 url(/img/ico_arrow_right.svg) center center no-repeat;
      right: 0;
      top: 0;
      width: 37px;
      height: 100%;
      content: "";
    }
  }
}
