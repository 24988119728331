@import "functions";
@import "variables";

body {
  position: relative;
  overflow-x: hidden;
  min-width: 320px;

  #bubble {
    position: absolute;
    padding: 20px;
    background: $white;
    top: 0;
    left: 0;
    display: none;
    box-shadow: 0 10px 30px rgba($black, 0.1);
    z-index: 150;

    &:after {
      content: '';
      width: 10px;
      height: 14px;
      background: url('/img/ico_triangle_right.svg') right center no-repeat;
      top: calc(50% - 7px);
      left: 100%;
      display: block;
      position: absolute;
    }

    span {
      @include font();
      display: block;
      font-size: $size_text_small;

      &:first-of-type {
        @include fontBold();
      }
    }
  }

  #location_bubble {
    position: absolute;
    padding: 12px;
    background: $white;
    top: 0;
    left: 0;
    display: none;
    box-shadow: 0 10px 30px rgba($black, 0.1);
    z-index: 150;

    &:after {
      content: '';
      width: 10px;
      height: 14px;
      background: url('/img/ico_triangle_right.svg') right center no-repeat;
      top: calc(50% - 7px);
      left: 100%;
      display: block;
      position: absolute;
    }

    span {
      display: block;
      font-size: $size_text_mini;
      line-height: $size_text_mini;
      text-transform: uppercase;
      @include font();
    }
  }

  &.home {
    .home-header {
      /*background: rgb(0,0,0);
      background: linear-gradient(
                      90deg,
                      rgba(0,0,0,1) 0%,
                      rgba(0,0,0,0.4023984593837535) 0%,
                      rgba(0,0,0,0.41360294117647056) 24%,
                      rgba(45,45,45,0) 64%,
                      rgba(130,130,130,0) 100%
      );
      //background-image: url('/img/home.jpg');
      background-position: left center, bottom center;
      background-repeat: no-repeat, no-repeat;
      background-size: cover, cover;*/
      position: relative;

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;

        background: #000000;
        background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.415) 24%, rgba(45, 45, 45, 0) 64%, rgba(130, 130, 130, 0) 100%);
      }

      @media #{$media_md} {
        &_circles {
          background: linear-gradient(95deg, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.4) 95%);
        }
      }
      @media #{$media_lg} {
        padding-bottom: 0;
      }

      h1 {
        font-size: 3rem;
        //margin-bottom: 4rem;
        margin-bottom: 1rem;
        line-height: 1.2;

        @media #{$media_sm} {
          font-size: 5rem;
          margin-bottom: 2rem;
        }
      }

      .circles {
        position: relative;
        z-index: 5;

        &.first-circles {
          margin-top: 80px
        }
      }

      .home-border-mobile {
        border-top: 1px solid rgba(255, 255, 255, 0.4);
      }

      .circles-content {
        text-align: left;

        h2 {
          font-size: 1.75rem;
          line-height: 2rem;
          margin-bottom: 1.5rem;

          span {
            color: $web_gold
          }
        }

        h2,
        p {
          margin-bottom: 1.5rem
        }

        .white-hover {
          &:hover {
            color: white;
          }
        }
      }

      @media #{$media_md} {
        height: 800px;
        min-height: 100vh;
        &_circles {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          margin-bottom: 271px;

          &:after {
            position: absolute;
            width: 1px;
            height: 271px;
            content: "";
            left: 50%;
            bottom: -271px;
            background: rgba(255, 255, 255, 0.4);
          }
        }
        .circles {
          //padding: 5rem  0 0 0;
          padding: 4rem 0 0 0;

          &.first-circles {
            margin-top: 0
          }
        }
        .home-border-desktop {
          border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        }
        .home-border-mobile {
          border-top: none
        }
      }
    }

    .scroll-down {
      text-align: center;
      position: relative;
      margin-top: -78px;
      margin-bottom: 50px;

      @media #{$media_md} {
        margin-bottom: 20px;
        margin-top: -54px
      }

      &_btn {
        background: $web_gold;
        width: 54px;
        height: 54px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
      }
    }

    .content-about {
      padding-top: 30px;

      .row:last-of-type {
        margin-bottom: 20px
      }

      @media #{$media_md} {
        padding-top: 100px
      }
      @media #{$media_max_md} {
        h2 {
          margin-bottom: 20px
        }
      }
    }

    .content-map {
      img {
        width: 100%;
        max-width: 100%
      }

      &-gold {
        padding: 1.5rem 1.5rem 2rem;
        background: $web_beige
      }

      &-white {
        padding-top: 40px;
        padding-bottom: 60px
      }

      p {
        color: $web_dark
      }

      @media #{$media_max_md} {
        &-row {
          display: none;
          max-width: 100%
        }
        &-col {
          padding: 0;
        }
        .map-button {
          margin-bottom: 0
        }
      }
      @media #{$media_md} {
        &-row {
          display: block
        }
        &-gold {
          position: relative;
          top: -100px;
          margin-bottom: -100px;
          padding: 50px;
          background: $web_beige
        }
        &-white {
          padding: 80px 65px 0
        }
      }
    }

    .content-byvanie {
      margin-bottom: 60px;

      h2 {
        color: $web_dark;

        span {
          color: $web_gold;
          display: block;
          font-size: $size_text_small;
          line-height: 1.5*$size_text_small;
          margin-top: 20px;
          @include fontBold()
        }
      }

      p {
        color: $web_dark;
      }

      h2, p {
        margin-bottom: 40px
      }

      &-gold {
        background: linear-gradient(180deg, #F9F1E1 0%, rgba(249, 241, 225, 0) 100%);
        padding: 150px 50px 50px
      }

      @media #{$media_md} {
        padding-top: 120px;
        margin-bottom: 100px;
        h2, p {
          margin-bottom: 80px
        }
        &-gold {
          margin-left: 50px;
          position: relative;
          top: -100px;
          margin-bottom: -100px;
        }
      }
      @media #{$media_max_md} {
        &-gold-mob {
          top: -150px;
          margin-bottom: -150px;
          background: linear-gradient(180deg, #F9F1E1 0%, rgba(249, 241, 225, 0) 100%);
          padding: 150px 1.5rem 0
        }
      }
      @media #{$media_max_xs} {
        &-gold-mob {
          padding: 150px .75rem 0
        }
      }

    }

    .content-benefits {
      margin-bottom: 60px;
      @media #{$media_md} {
        margin-bottom: 125px;
      }

      &-btn {
        background: $web_blue;
        border: none;
        width: 100%;
        padding: 50px 1.5rem;
        position: relative;
        margin-bottom: 1.5rem;

        h3 {
          font-size: 1.125rem;
          line-height: 1.25rem;
          color: white;
          margin-bottom: 0;
          margin-top: 25px;
        }

        svg {
          display:inline;
          fill: $web_gold
        }

        &.active,
        &.acc-btn:not(.collapsed) {
          margin-bottom: 85px;
          background: $web_gold;

          svg {
            display:inline;
            fill: $web_blue
          }

          &:before {
            content: '';
            width: 14px;
            height: 85px;
            position: absolute;
            left: calc(50% - 7px);
            bottom: -70px;
            background: url('/img/arrow-benefit.png') center center no-repeat;
            background-size: contain;
          }
        }
      }

      &-text {
        border: 1px solid #F9F1E1;
        padding: 20px;
        position: relative;
        margin-bottom: 2rem;

        h4, p {
          color: $web_dark
        }

        &-left {
          width: calc(100% - 375px)
        }

        &-img {
          position: absolute;
          width: 330px;
          right: 50px;
          padding: 0
        }
      }

      @media #{$media_md} {
        &-text {
          min-height: 280px;
          padding: 50px;
        }
      }
    }
  }

  &.gallery {
    header {
      background-image: linear-gradient(180deg, rgba($web_dark, 0.5) 0%, rgba($web_dark, 0) 100%);
      background-position: right top;
      background-repeat: no-repeat;
      background-size: cover;
      padding-bottom: 25px;
      @media #{$media_sm} {
        padding-bottom: 40px;
      }

      .menu-col {
        flex-wrap: wrap;
        flex-grow: 0;
        flex-shrink: 0;
        @media #{$media_sm} {
          flex-wrap: nowrap;
          flex-grow: initial;
          flex-shrink: initial
        }

        & > a {
          flex-basis: 40%;
          @media #{$media_sm} {
            flex-basis: auto;
          }
        }
      }

      .gallery-categories {
        display: flex;
        order: 3;
        flex-basis: 100%;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: nowrap;
        @media #{$media_sm} {
          display: inline-block;
          order: 2;
          flex-basis: auto;
        }
      }

      #toggle {
        order: 2;
        @media #{$media_sm} {
          order: 3;
        }
      }
    }
  }

  .gallery-categories {
    display: none;
    padding-top: 15px;
    @media #{$media_sm} {
      padding-top: 0;
    }

    a {
      font-size: $size_text_small;
      line-height: 1.5*$size_text_small;
      border: 1px solid $web_gold;
      color: $white;
      text-transform: uppercase;
      position: relative;
      padding: 17px 31px;
      margin: 6px;
      display: inline-block;
      @include fontBold();
      @media #{$media_sm} {
        padding: 17px 51px;
      }

      &:hover {
        background: $web_gold;
      }

      &.active {
        background: $web_gold;
        padding: 17px 21px 17px 41px;
        @media #{$media_sm} {
          padding: 17px 51px;
        }

        &:before {
          content: '';
          width: 22px;
          height: 22px;
          position: absolute;
          top: calc(50% - 9px);
          left: 8px;
          background: url('/img/ico_mark.svg') center center no-repeat;
          @media #{$media_sm} {
            left: 13px;
          }
        }
      }
    }
  }

  .home-content {
    padding-top: 90px;
    margin-bottom: 40px;
    @media #{$media_md} {
      padding-top: 180px;
      margin-bottom: 200px;
    }

    h1 {
      color: $white;
      font-size: 0.5*$size_home;
      line-height: 0.5*1.3*$size_home;
      text-align: center;
      margin-bottom: 45px;
      @media #{$media_md} {
        margin-bottom: 15px;
        text-align: left;
        font-size: $size_home;
        line-height: 1.3*$size_home;
      }
    }

    .col-12 {
      position: relative;
    }

    .home-extra {
      background: rgba($black, 0.5);
      padding-top: 115px;
      padding-bottom: 30px;
      position: relative;
      max-width: 300px;
      margin: 30px auto 60px;
      @media #{$media_md} {
        position: absolute;
        width: 280px;
        height: 360px;
        top: 0;
        right: 0;
        padding-bottom: 0;
        margin: 0;
      }

      &:before {
        content: '';
        background: $web_gold url('/img/ico_mail.svg') center center no-repeat;
        width: 56px;
        height: 56px;
        top: 0;
        left: calc(50% - 28px);
        position: absolute;
      }

      p {
        font-size: $size_text_big;
        line-height: 1.5*$size_text_big;
        color: $white;
        padding-left: 45px;
        padding-right: 45px;
        text-align: center;
        margin-bottom: 55px;
      }

      .withGoldUnderline {
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
        margin-bottom: 65px;
        @include fontBold();
      }
    }
  }

  .contact-content {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%), url('/img/contact.jpg');
    background-position: left center, left center;
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    padding-top: 190px;
    padding-bottom: 80px;
    margin-bottom: 60px;
    @media #{$media_md} {
      padding-bottom: 0;
      margin-bottom: 150px;
      padding-top: 250px;
      &.form-sended {
        margin-bottom: 200px;
      }
    }

    h1 {
      margin-bottom: 70px;
      text-align: center;
      font-size: 0.7*$size_h1;
      line-height: 0.7*1.5*$size_h1;
      @media #{$media_md} {
        text-align: left;
        margin-bottom: 100px;
        font-size: $size_h1;
        line-height: 1.5*$size_h1;
      }
    }

    p {
      margin-bottom: 50px;
      font-size: $size_big;
      line-height: 1.5*$size_big;
      text-align: center;
      @media #{$media_md} {
        text-align: left;
        margin-bottom: 75px;
      }
    }

    .withGoldLine {
      color: $white;
      display: block;
      font-size: 0.7*$size_contact_l;
      line-height: 0.7*1.5*$size_contact_l;
      text-align: center;
      margin-bottom: 15px;
      @media #{$media_md} {
        text-align: left;
        font-size: $size_contact_l;
        line-height: 1.5*$size_contact_l;
        margin-bottom: 25px;
      }
    }

    .withGoldUnderline {
      margin-bottom: 70px;
      @media #{$media_md} {
        margin-bottom: 115px;
      }
    }

    .form-col {
      position: relative;

      form {
        position: relative;
        @media #{$media_md} {
          position: absolute;
          top: 0;
        }

        .form-check {
          .form-check-input {
            &[type="checkbox"] {
              top: 0;
              @media (min-width: 440px) {
                top: -6px;
              }
            }
          }
        }

        button {
          line-height: 1.5*$size_text_small;
          @media #{$media_xs} {
            line-height: $size_text_small;
          }
        }
      }
    }
  }

  .developer-row {
    h2 {
      color: $web_dark;
      margin-bottom: 45px;
    }
  }

  .header-404 {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.4) 100%), url('/img/404.png');
    background-position: left center, center center;
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    margin-bottom: 100px;
    height: 180px;
    @media #{$media_md} {
      height: 320px;
    }
  }

  .content-404 {
    h2 {
      color: $web_dark;
      text-align: center;
      margin-bottom: 40px;
    }

    p {
      color: $web_dark;
      display: block;
      width: 260px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 50px;
      @media #{$media_md} {
        width: 300px;
      }
    }

    .withGoldUnderline {
      color: $web_dark;
      margin: 0 auto 100px;
      @include fontBold();
    }
  }

  .list-header {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.4) 100%), url('/img/list.png');
    background-position: left center, center center;
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    margin-bottom: 70px;
    @media #{$media_xs} {
      margin-bottom: 100px;
    }

    .list-flat-filter {
      padding-top: 100px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      align-items: center;
      align-content: center;
      @media #{$media_md} {
        flex-wrap: wrap;
        padding-top: 160px;
      }

      .flat-filter-item {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        border: 1px solid rgba($white, 0.4);

        &:nth-child(5) {
          display: none;
        }

        @media #{$media_md} {
          width: calc(20%);
          height: 150px;
          margin: 0;
          border: 0;
          border-top: 1px solid rgba($white, 0.4);
          border-right: 1px solid rgba($white, 0.4);
          &:nth-child(5) {
            display: block;
            border-right: 0;
          }
        }
        @media #{$media_lg} {
          height: 190px;
        }
        /*@media #{$media_xs} {
    border-right: 1px solid rgba($white, 0.4);

    &:nth-child(1) {
        width: calc(50%);
    }

    &:nth-child(2) {
        width: calc(50%);
        border-right: 0;
    }

    &:nth-child(3) {
        width: calc(50%);
    }

    &:nth-child(4) {
        width: calc(50%);
        border-right: 0;
    }

    &:nth-child(5) {
        width: calc(100%);
    }

}

@media #{$media_sm} {

    &:nth-child(1) {
        width: calc(33.3%);
    }

    &:nth-child(2) {
        width: calc(33.3%);
        border-right: 1px solid rgba($white, 0.4);
    }

    &:nth-child(3) {
        width: calc(33.4%);
        border-right: 0;
    }

    &:nth-child(4) {
        width: calc(50%);
        border-right: 1px solid rgba($white, 0.4);
    }

    &:nth-child(5) {
        width: calc(50%);
    }

}

@media #{$media_lg} {

    &:nth-child(1) {
        width: calc(20%);
    }

    &:nth-child(2) {
        width: calc(20%);
    }

    &:nth-child(3) {
        width: calc(20%);
        border-right: 1px solid rgba($white, 0.4);
    }

    &:nth-child(4) {
        width: calc(20%);
    }

    &:nth-child(5) {
        width: calc(20%);
        border-right: 0;
    }

}*/
        div {
          display: block;
          height: auto;
          padding: 6px 10px;
          width: 60px;
          @media #{$media_xs} {
            padding: 20px 15px;
            margin: 10px 15px;
            width: auto;
          }
          @media #{$media_md} {
            padding: 20px 15px;
            margin: 10px 15px;
            height: calc(100% - 20px);
          }
          @media #{$media_lg} {
            padding: 29px 33px;
            margin: 19px 16px;
            height: calc(100% - 38px);
          }

          .type {
            display: block;
            font-size: $size_text_small;
            line-height: 1.5*$size_text_small;
            @media #{$media_xs} {
              font-size: $size_text;
              line-height: 1.5*$size_text_small;
            }
            @media #{$media_lg} {
              font-size: $size_big2;
              line-height: 1.5*$size_big2;
            }
          }

          .free {
            display: none;
            margin-top: 5px;
            border: 1px solid $white;
            padding: 6px 16px;
            @media #{$media_md} {
              margin-top: 15px;
              padding: 5px 14px 5px 9px;
              display: inline-block;
            }
          }
        }

        &:hover {
          color: $white;

          div {
            background: $web_gold2;

            .free {
              background: $web_gold;
              border: 1px solid $web_gold;
            }
          }
        }

        &.active {
          div {
            background: $web_gold;

            .free {
              background: $web_gold2;
              border: 1px solid $web_gold2;
            }
          }
        }
      }
    }

    .mobile-filter-all {
      text-align: center;
      color: $web_gold;
      text-decoration: underline;
      padding-top: 19px;
      padding-bottom: 19px;
      text-transform: uppercase;
      font-size: $size_text_mini;
      line-height: 1.5*$size_text_mini;
      @include font();
    }
  }

  .flat-list {
    h2 {
      color: $web_dark;
      margin-bottom: 15px;
      font-size: 0.7*$size_h2;
      line-height: 0.7*1.3*$size_h2;
      @media #{$media_sm} {
        font-size: $size_h2;
        line-height: 1.5*$size_h2;
      }
    }

    .flat-list-filter-row {
      padding-bottom: 50px;

      .list-type {
        p {
          color: $web_gold;
          text-transform: uppercase;
          font-size: $size_text_small;
          line-height: 1.5*$size_text_small;
          margin-bottom: 0;
          display: flex;
          height: 100%;
          align-items: flex-end;
        }
      }

      .flat-list-filter-opener {
        box-shadow: 0 10px 50px rgba(0, 0, 0, 0.15);
        background: url('/img/ico_gold_arrow_down.svg') 95% center no-repeat;
        text-transform: uppercase;
        text-align: center;
        font-size: $size_text_small;
        line-height: 1.5*$size_text_small;
        color: $web_dark;
        padding: 19px 90px;
        border-bottom: 2px solid $white;
        margin-top: 20px;
        display: block;
        @include font();
        @media #{$media_sm} {
          display: none;
        }

        .opened {
          display: none;
        }

        &.show {
          background: url('/img/ico_gold_arrow_up.svg') 95% center no-repeat;
          border-bottom: 2px solid $web_gold;

          .opened {
            display: inline-block;
          }
        }
      }

      .flat-list-filter-holder {
        display: none;

        &.show {
          display: flex;
          margin-top: 10px;
          background: $white;
          flex-direction: column;
          box-shadow: 0 10px 50px rgba($black, 0.15);
          padding-top: 25px;
          padding-bottom: 10px;
        }

        @media #{$media_sm} {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          margin-top: 15px;
          box-shadow: none !important;
          background: transparent !important;
          display: flex;
          flex-direction: row !important;
          align-content: flex-start;
          align-items: flex-start;
          flex-wrap: nowrap;
          justify-content: flex-end;
          .dropdown {
            margin-left: 0;
          }
        }
        @media #{$media_md} {
          margin-top: 0;
          align-content: flex-start;
          align-items: flex-start;
          flex-wrap: nowrap;
          justify-content: flex-end;
          .dropdown {
            margin-left: 20px;
          }
        }
      }
    }

    .flat-list-definition-row {
      .list-definition {
        p {
          color: $web_dark;
          font-size: $size_text_mini;
          line-height: 1.5*$size_text_mini;
          @include font();

          & > span {
            display: inline-block;
            padding-bottom: 10px;
            padding-right: 10px;

            span {
              padding: 3px 8px 4px;
              display: inline-block;
              color: $white;
              @include font();

              &.free {
                background: $web_blue;
              }

              &.sold {
                background: $web_gold;
              }

              &.reserved {
                background: $web_gray;
              }
            }
          }
        }
      }
    }

    .flat-list-row {
      & > .col-12 {
        padding-left: 0 !important;
        padding-right: 0 !important;
        @media #{$media_xs} {
          padding-right: calc(var(--bs-gutter-x) / 2);
          padding-left: calc(var(--bs-gutter-x) / 2);
        }
      }

      .flat-list-list-outter {
        margin-bottom: 40px;

        .foot-note {
          color: rgb(31, 31, 31);
          padding-top: 2.7rem;
        }

        @media #{$media_sm} {
          margin-bottom: 120px;
        }

        .flat-list-list-row {
          display: flex;
          flex-wrap: nowrap;
          border-bottom: 1px solid $web_gray4;

          &.top {
            border-bottom: 0;
            background: $web_beige;

            div {
              text-transform: uppercase;
              font-size: $size_text_mini;
              line-height: 1.5*$size_text_mini;
              color: $web_dark;
              padding: 7px 12px;
              @include fontBold();

              @media #{$media_sm} {
                padding: 15px 20px;
                font-size: $size_text_small;
                line-height: 1.5*$size_text_small;
              }

              @media #{$media_md} {
                padding: 21px 31px;
              }


              &.flat-size {
                text-transform: uppercase;
              }

              &.flat-lodge {
                text-transform: uppercase;
              }

              &.flat-total {
                text-transform: uppercase;
              }

              &.flat-price {
                color: $web_dark;
              }
            }
          }

          &:last-child {
            border-bottom: 0;
          }

          & > span {
            text-transform: uppercase;
            font-size: $size_text_mini;
            line-height: 1.5*$size_text_mini;
            color: $web_dark;
            padding: 13px 12px 7px;
            display: inline-block;

            @media #{$media_sm} {
              padding: 15px 20px;
              font-size: $size_text_small;
              line-height: 1.5*$size_text_small;
            }

            @media #{$media_md} {
              padding: 27px 31px;
            }

            &:hover {
              color: $web_dark;
            }
          }

          .flat-type {
            @include fontBold();
            display: none;
            @media #{$media_xs} {
              display: block;
              width: 11%;
            }
            @media #{$media_sm} {
              width: 11%;
            }
            @media #{$media_md} {
              width: 6%;
            }
            @media #{$media_lg} {
              width: 10%;
            }
          }

          .flat-number {
            width: 17%;
            @media #{$media_xs} {
              width: 12%;
            }
            @media #{$media_sm} {
              width: 12%;
            }
            @media #{$media_md} {
              width: 8%;
            }
            @media #{$media_lg} {
              width: 10%;
            }
          }

          .flat-cp-name {
            width: 25%;
            @media #{$media_md} {
              width: 14%;
            }
            @media #{$media_lg} {
              width: 12%;
            }
          }

          .flat-purpose {
            width: 22%;
            @media #{$media_lg} {
              width: 17%;
            }
          }

          .flat-floor {
            display: none;
            text-align: center;
            @media #{$media_xs} {
              width: 11%;
              display: block;
            }
            @media #{$media_sm} {
              width: 11%;
            }
            @media #{$media_md} {
              width: 8%;
            }
            @media #{$media_lg} {
              width: 10%;
            }
          }

          .flat-rooms {
            width: 14%;
            text-align: center;
            @media #{$media_xs} {
              width: 9%;
            }
            @media #{$media_sm} {
              width: 9%;
            }
            @media #{$media_md} {
              width: 7%;
            }
            @media #{$media_lg} {
              width: 10%;
            }
          }

          .flat-size {
            width: 12%;
            text-transform: none;
            display: none;
            text-align: center;
            white-space: nowrap;
            @media #{$media_xs} {
            }
            @media #{$media_sm} {
            }
            @media #{$media_md} {
              width: 12%;
              display: block;
            }
            @media #{$media_lg} {
              width: 13%;
            }
          }

          .flat-lodge {
            width: 11%;
            text-transform: none;
            display: none;
            white-space: nowrap;
            @media #{$media_xs} {
            }
            @media #{$media_sm} {
            }
            @media #{$media_md} {
              width: 11%;
              display: block;
            }
            @media #{$media_lg} {
              width: 11%;
            }
          }

          .flat-total {
            width: 23%;
            text-transform: none;
            white-space: nowrap;
            @media #{$media_xs} {
              width: 13%;
            }
            @media #{$media_sm} {
              width: 13%;
            }
            @media #{$media_md} {
              width: 12%;
            }
            @media #{$media_lg} {
              width: 11%;
            }
          }

          .flat-price {
            width: 30%;
            color: $web_gold;
            text-align: center;
            @include fontBold();
            @media #{$media_xs} {
              width: 20%;
            }
            @media #{$media_sm} {
              width: 20%;
            }
            @media #{$media_md} {
              width: 16%;
            }
            @media #{$media_lg} {
              width: 12%;
            }

            &:hover {
              color: $web_gold;
            }
          }

          .flat-state {
            width: 17%;
            text-align: right;
            padding: 7px 12px 7px;
            @media #{$media_sm} {
              width: 26%;
            }
            @media #{$media_md} {
              width: 20%;
              padding: 21px 31px;
            }
            @media #{$media_lg} {
              width: 15%;
            }

            span {
              padding: 3px 8px 4px;
              display: inline-block;
              color: $white;
              @media #{$media_sm} {
                padding: 5px 10px 6px;
              }

              &.free {
                background: $web_blue;
              }

              &.sold {
                background: $web_gold;
              }

              &.reserved {
                background: $web_gray;
              }
            }
          }
        }
      }
    }
  }

  .gallery-holder {
    position: relative;
    min-height: 100vh;
    /*@media #{$media_md} {
     height: 750px;
 }

 @media #{$media_xl} {
     height: 900px;
 }*/
    .small-gllery-holder {
      position: relative;
    }
  }

  .header-personal-data {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.4) 100%), url('/img/404.png');
    background-position: left center, center center;
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    margin-bottom: 100px;
    height: 180px;
    @media #{$media_md} {
      height: 320px;
    }
  }

  .content-personal-data {
    h2 {
      color: $web_dark;
      text-align: center;
      margin-bottom: 40px;
    }

    p {
      color: $web_dark;
      display: block;
      margin-bottom: 30px;
      font-weight: 400 !important;

      strong {
        @include fontBold();
      }
    }

    ol {
      margin-bottom: 80px;
      -webkit-padding-start: 0;
      list-style-position: inside;

      li {
        padding-bottom: 10px;
        font-size: $size_text;
        line-height: 1.5*$size_text;
        @include font();

        span {
          margin-bottom: 10px;
        }

        ol {
          list-style: upper-roman;
          margin-bottom: 0;
          margin-top: 5px;
          padding-left: 50px;
        }
      }
    }
  }

  .header-cookies {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.4) 100%), url('/img/404.png');
    background-position: left center, center center;
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    margin-bottom: 100px;
    height: 180px;
    @media #{$media_md} {
      height: 320px;
    }
  }

  .content-cookies {
    h2 {
      color: $web_dark;
      text-align: center;
      margin-bottom: 40px;
    }

    p {
      color: $web_dark;
      display: block;
      margin-bottom: 30px;
      font-weight: 400 !important;

      strong {
        @include fontBold();
      }

      a {
        color: $web_dark;
        @include fontBold();

        &:hover {
          text-decoration: underline;
        }
      }

      &:last-of-type {
        margin-bottom: 80px;
      }
    }

    ol {
      margin-bottom: 20px;
      -webkit-padding-start: 0;
      list-style-position: inside;

      li {
        padding-bottom: 10px;
        font-size: $size_text;
        line-height: 1.5*$size_text;
        @include fontRegular();

        span {
          margin-bottom: 10px;
        }
      }
    }
  }

  .detail-info {
    height: $detail_header_height;
    position: absolute;
    top: 0;
    z-index: 100;
    width: 100vw;

    h1 {
      padding-top: 190px;
      margin-bottom: 25px;
      text-align: center;
      @media #{$media_sm} {
        text-align: left;
        padding-left: 40px;
      }
      @media #{$media_xl} {
        padding-left: 0;
      }
    }

    p {
      margin-bottom: 15px;
      text-align: center;
      @media #{$media_sm} {
        text-align: left;
        margin-bottom: 25px;
        padding-left: 40px;
      }
      @media #{$media_xl} {
        padding-left: 0;
      }

      span {
        background: $web_gold;
        color: $white;
        font-size: $size_text;
        line-height: 1.5*$size_text;
        padding: 6px 10px;
        margin-right: 10px;
        display: inline-block;
        margin-bottom: 7px;
        @include fontRegular();
        @media #{$media_sm} {
          display: inline-block;
        }

        &.state {
          text-transform: uppercase;

          &.free {
            background: $web_blue;
          }

          &.sold {
            background: $web_gold;
          }

          &.reserved {
            background: $web_gray;
          }
        }
      }

      &#detail-carousel-slides {
        margin-bottom: 20px;
        @media #{$media_xs} {
          margin-bottom: 40px;
        }

        span {
          font-size: $size_text_small;
          line-height: 1.5*$size_text_small;
          color: $white;
          position: relative;
          background: transparent;
          padding: 6px 10px;
          padding-right: 35px;
          margin-right: 0;
          @include fontRegular();

          &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            width: 24px;
            height: 1px;
            background: $white;
          }

          &.actual {
            padding-left: 0;
          }

          &.total {
            opacity: 0.3;
            padding-right: 10px;

            &:after {
              display: none;
            }
          }
        }
      }
    }

    .back {
      display: block;
      color: $white;
      font-size: $size_text_small;
      line-height: 1.5*$size_text_small;
      text-align: center;
      @media #{$media_sm} {
        margin-left: 40px;
        text-align: left;
      }
      @media #{$media_xl} {
        margin-left: 0;
      }

      img {
        display: inline-block;
        margin-right: 9px;
      }
    }
  }

  .detail-standard {
    padding-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid $web_gray4;
    @media #{$media_md} {
      padding-top: 40px;
    }

    .detail-standard-holder {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      margin-bottom: 0;
      @media #{$media_md} {
        display: block;
      }

      a {
        color: $web_dark;
        font-size: $size_text;
        line-height: 1.5*$size_text;
        display: inline-block;
        padding-right: 0;
        @include font();
        @media #{$media_md} {
          padding-right: 60px;
        }

        &:last-of-type {
          padding-right: 0;
        }

        img {
          display:inline;
          margin-right: 8px;
          position: relative;
          top: -2px;
        }
      }
    }
  }

  .detail-detail {
    margin-bottom: 50px;
    @media #{$media_md} {
      margin-bottom: 150px;
    }

    .detail-detail-info {
      width: 100%;
      margin-bottom: 35px;
      margin-top: 60px;
      @media #{$media_md} {
        width: 300px;
      }

      p {
        color: $web_dark;
        font-size: $size_text_small;
        line-height: 1.5*$size_text_small;
        margin-bottom: 25px;
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        flex-wrap: nowrap;
        flex-direction: row;
        @include font();

        span {
          text-align: right;
        }

        strong {
          @include fontBold();
        }
      }
    }

    .detail-price-holder {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      flex-direction: column;
      align-content: center;
      align-items: flex-start;
      column-gap: 1rem;
      @media #{$media_xs} {
        flex-direction: row;
        align-items: center;
      }
      @media #{$media_lg} {
        padding-right: 40px;
      }

      a {
        width: 220px;
        padding: 28px 53px 28px 36px;
        position: relative;
        background: $web_gold;
        color: $white;
        border: 0;
        height: 72px;
        box-shadow: none;
        text-transform: uppercase;
        font-size: $size_text_small;
        line-height: $size_text_small;
        order: 2;
        margin-bottom: 0;
        @include fontBold();
        @media #{$media_xs} {
          order: 1;
        }
        @media #{$media_md} {
          margin-bottom: 0;
        }
        @media #{$media_lg} {
          width: 100%;
          max-width: 300px;
        }

        &:after {
          position: absolute;
          background: $web_gold2 url('/img/ico_arrow_right.svg') center center no-repeat;
          right: 0;
          top: 0;
          width: 37px;
          height: 100%;
          content: '';
        }

        &:hover {
          background: $web_gold2;
        }
      }

      p {
        font-size: $size_price;
        line-height: 1*$size_price;
        color: $web_blue;
        margin-bottom: 15px;
        height: 72px;
        order: 1;
        white-space: nowrap;
        @include font();
        @media #{$media_xs} {
          order: 2;
          margin-bottom: 0;
        }

        span {
          font-size: $size_text_mini;
          line-height: 1.5*$size_text_mini;
          color: $web_gold;
          display: block;
          margin-bottom: 13px;
          @include fontBold();
        }
      }
    }

    .detail-floor-plan-outter {
      position: relative;

      .detail-floor-plan-holder {
        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.1);
        display: block;
        width: 100%;
        z-index: 100;
        background: $white;
        position: relative;
        min-height: calc(250px + 100px);
        margin-top: 30px;
        @media #{$media_xs} {
          min-height: calc(400px + 100px);
        }
        @media #{$media_md} {
          top: -130px;
          height: calc(100% + 130px + 50px);
          position: absolute;
          margin-top: 0;
        }

        .zoom {
          display: flex;
          width: 54px;
          height: 54px;
          position: absolute;
          top: 0;
          right: 0;
          background: $web_blue url('/img/ico_zoom.svg') center center no-repeat;

          &:hover {
            background-color: $web_gold;
          }
        }

        & > svg {
          display: block;
          width: 100%;
          padding: 30px;
          height: 250px;
          object-fit: contain;
          object-position: center center;
          margin-top: 30px;
          @media #{$media_xs} {
            height: 400px;
          }
          @media #{$media_md} {
            order: 2;
            margin-bottom: 0;
            margin-top: 0;
            height: 100%;
            max-height: calc(100% - 93px);
            g[data-title] {
              cursor: pointer;
              fill: $web_gold;

              &:hover {
                opacity: 0.8;
                fill-opacity: 0.8;
              }
            }
          }
        }

        .detail-floor-plan-links {
          display: flex;
          align-content: center;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          @media #{$media_xs} {
            flex-direction: row;
          }

          a {
            border-top: 1px solid $web_gray4;
            color: $web_gold;
            text-align: center;
            font-size: $size_text_small;
            line-height: 1.5*$size_text_small;
            padding: 12px 20px;
            width: 100%;
            text-transform: uppercase;
            @media #{$media_xs} {
              width: 50%;
              padding: 34px 20px;
            }

            img {
              margin-right: 12px;
              display:inline;
            }

            &:first-of-type {
              border-right: 0;
              @media #{$media_xs} {
                border-right: 1px solid $web_gray4;
              }
            }
          }
        }
      }
    }
  }

  .detail-contact {
    margin-bottom: 60px;
    position: relative;
    @media #{$media_md} {
      margin-bottom: 110px;
    }

    &:after {
      position: absolute;
      top: 55px;
      left: 15px;
      width: calc(100% - 30px);
      height: calc(100%);
      background: $web_beige url('/img/detail_contact_background.svg') 100% 100% no-repeat;
      content: '';
      z-index: 1;
      @media #{$media_md} {
        height: calc(100% - 55px - 55px);
      }
    }

    & > .row {
      z-index: 2;
      position: relative;
    }

    form {
      margin-left: 10px;
      margin-right: 10px;
      width: calc(100% - 20px);
      @media #{$media_xs} {
        margin-left: 30px;
        margin-right: 30px;
        width: calc(100% - 60px);
      }
      @media #{$media_md} {
        width: auto;
      }
    }

    .detail-contact-info {
      padding-top: 100px;
      padding-left: 0;
      @media #{$media_md} {
        padding-top: 170px;
        padding-left: 40px;
      }

      h6 {
        color: $web_gold;
        text-transform: uppercase;
        margin-bottom: 35px;
        text-align: center;
        @media #{$media_md} {
          display: inline-block;
          text-align: left;
        }
      }

      p {
        a {
          color: $web_dark;
          font-size: 0.6*$size_info;
          line-height: 0.6*1.5*$size_info;
          margin-bottom: 20px;
          display: block;
          text-align: center;
          @media #{$media_xs} {
            font-size: 0.8*$size_info;
            line-height: 0.8*1.5*$size_info;
          }
          @media #{$media_md} {
            display: inline-block;
            text-align: left;
          }
          @media #{$media_lg} {
            font-size: $size_info;
            line-height: 1.5*$size_info;
          }
        }
      }
    }
  }

  .detail-floor-location-holder {
    position: relative;
    margin-bottom: 50px;
    @media #{$media_md} {
      margin-bottom: 90px;
    }

    .detail-floor-location-outter {
      display: flex;
      justify-content: center;
      @media #{$media_md} {
        justify-content: flex-end;
      }

      .detail-floor-location {
        padding-bottom: 40px;
        width: 100%;
        text-align: center;
        @media #{$media_md} {
          width: calc((960px / 2) - 30px);
        }
        @media #{$media_lg} {
          width: calc((1140px / 2) - 30px);
        }
        @media #{$media_llg} {
          width: calc((1320px / 2) - 30px);
        }

        h2 {
          color: $web_dark;
          margin-bottom: 15px;
          font-size: 0.7*$size_h2;
          line-height: 0.7*1.3*$size_h2;
          text-align: center;
          @media #{$media_sm} {
            font-size: $size_h2;
            line-height: 1.3*$size_h2;
          }
          @media #{$media_md} {
            text-align: left;
          }
        }

        p {
          color: $web_gold;
          margin-bottom: 30px;
          text-align: center;
          @media #{$media_md} {
            text-align: left;
          }
        }

        img {
          width: 90%;
          margin-left: 5%;
          margin-right: 5%;
          max-height: 400px;
          @media #{$media_md} {
            margin-left: 0;
            margin-right: 0;
            max-height: 250px;
          }
          @media #{$media_lg} {
            max-height: 360px;
          }
          @media #{$media_xl} {
            max-height: 550px;
          }
        }
      }
    }

    .detail-floor-location-outside-holder {
      position: relative;
      overflow: hidden;
      padding-left: 0;
      padding-right: 0;

      svg {
        object-fit: cover;
        object-position: center center;
        width: 100%;
        height: 100%;
        max-width: 960px;

        a {
          g {
            cursor: pointer;
            position: relative;
            fill: $web_gold;
            fill-opacity: 0;
          }

          &.active[data-state="available"] {
            g {
              fill-opacity: 0.7;
            }
          }

          &.active[data-state="reserved"],
          &.active[data-state="sold"] {
            g {
              fill-opacity: 0.5;
            }
          }

          &:hover {
            g {
              fill-opacity: 0.4;
            }
          }
        }
      }
    }
  }

  .header-about {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.0) 100%), url('/img/investment/header_investicia.png');
    background-position: left center, bottom center;
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    margin-bottom: 150px;

    h1 {
      margin-top: 130px;
      margin-bottom: 80px;
      font-size: 0.7*$size_h1;
      line-height: 0.7*1.5*$size_h1;
      text-align: center;
      @media #{$media_md} {
        font-size: $size_h1;
        line-height: 1.5*$size_h1;
        margin-top: 210px;
        margin-bottom: 160px;
        text-align: left;
      }
    }
  }

  .content-about {
    .row {
      margin-bottom: 80px;

      &:last-of-type {
        margin-bottom: 20px;
      }

      @media #{$media_md} {
        margin-bottom: 160px;
        &:last-of-type {
          margin-bottom: 120px;
        }
      }
    }

    h2 {
      color: $web_dark;
      margin-bottom: 50px;

      span {
        color: $web_gold;
        display: block;
        font-size: $size_text_small;
        line-height: 1.5*$size_text_small;
        margin-top: 20px;
        @include fontBold();
      }
    }

    p {
      color: $web_dark;
      margin-bottom: 30px;
      width: 100%;
      @media #{$media_md} {
        max-width: 480px;
      }
    }

    .about-img-holder {
      display: block;
      position: relative;
      background: $web_beige;
      margin-bottom: 50px;
      margin-top: 50px;
      @media #{$media_md} {
        margin-bottom: 0;
      }

      img {
        position: absolute;
        width: 100%;
        max-width: 280px;
        height: 360px;
        object-fit: cover;
        object-position: center center;
        @media #{$media_xs} {
          max-width: 380px;
        }
      }

      &.right {
        width: 100%;
        height: 390px;
        @media #{$media_md} {
          max-width: 480px;
        }

        img {
          right: initial;
          left: calc(50% - 140px);
          @media #{$media_xs} {
            left: calc(50% - 190px);
          }
          @media #{$media_md} {
            right: 0;
            left: initial;
          }
        }
      }

      &.center {
        width: 100%;
        height: 370px;
        @media #{$media_md} {
          max-width: 580px;
        }

        img {
          right: initial;
          left: calc(50% - 140px);
          @media #{$media_xs} {
            left: calc(50% - 190px);
          }
          @media #{$media_md} {
            left: calc(50% - 190px);
          }
        }
      }

      &.top {
        img {
          top: -50px;
        }
      }
    }
  }

  .map-button {
    width: 220px;
    padding: 28px 53px 28px 36px;
    position: relative;
    display: inline-block;
    background: $web_gold;
    color: $white;
    border: 0;
    height: 72px;
    box-shadow: none;
    text-transform: uppercase;
    font-size: $size_text_small;
    line-height: $size_text_small;
    order: 2;
    margin-bottom: 50px;
    @include fontBold();
    @media #{$media_xs} {
      order: 1;
    }
    @media #{$media_md} {
      margin-bottom: 0;
    }

    &:after {
      position: absolute;
      background: $web_gold2 url('/img/ico_map_pin.svg') center center no-repeat;
      right: 0;
      top: 0;
      width: 37px;
      height: 100%;
      content: '';
    }

    &:hover {
      background: $web_gold2;
    }
  }

  .about-map {
    margin-bottom: 50px;
    @media #{$media_md} {
      margin-bottom: 160px;
    }

    h2 {
      color: $web_dark;
      margin-bottom: 50px;

      span {
        color: $web_gold;
        display: block;
        font-size: $size_text_small;
        line-height: 1.5*$size_text_small;
        margin-top: 20px;
        @include fontBold();
      }
    }

    p {
      color: $web_dark;
      margin-bottom: 30px;
      width: 100%;
      @media #{$media_md} {
        max-width: 480px;
      }
    }

    .map-filter {
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
      flex-direction: row;
      flex-wrap: wrap;
      height: 80px;
      overflow: hidden;
      margin-bottom: 40px;
      @media #{$media_xs} {
        height: auto;
        padding-top: 10px;
      }
      @media #{$media_sm} {
        margin-bottom: 0;
      }

      &.show {
        height: auto;
        border: 1px solid $web_beige;

        .map-filter-opener {
          margin-bottom: 20px;
        }

        a {
          padding-left: 20px;
          margin-bottom: 20px;
        }
      }

      .map-filter-opener {
        background: url('/img/ico_gold_arrow_down.svg') 95% center no-repeat;
        text-transform: uppercase;
        text-align: center;
        font-size: $size_text_small;
        line-height: 1.5*$size_text_small;
        color: $web_dark;
        padding: 19px 90px;
        display: block;
        width: 100%;
        margin-bottom: 30px;
        border: 1px solid $web_beige;
        @include font();
        @media #{$media_xs} {
          display: none;
        }

        .opened {
          display: none;
        }

        &.show {
          background: url('/img/ico_gold_arrow_up.svg') 95% center no-repeat;
          border-bottom: 2px solid $web_gold;

          .opened {
            display: inline-block;
          }
        }
      }

      a {
        flex-basis: 100%;
        color: $web_dark;
        font-size: $size_text_mini;
        line-height: 1.5*$size_text_mini;
        text-transform: uppercase;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        margin-bottom: 18px;
        @media #{$media_xs} {
          flex-basis: 50%;
        }

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          align-content: center;
          width: 46px;
          height: 46px;
          position: relative;
          background: $web_beige;
          border-radius: 50%;
          margin-right: 10px;

          svg {
            path {
              fill: $web_gold;
            }
          }
        }

        &.active {
          span {
            background: $web_gold;

            &:before {
              content: '';
              width: 17px;
              height: 17px;
              position: absolute;
              top: -4px;
              right: 0;
              background: url('/img/ico_mark_blue.svg') center center no-repeat;
            }

            svg {
              path {
                fill: $white;
              }
            }
          }
        }
      }
    }

    .mapouter {
      position: relative;
      text-align: right;
      height: 500px;
      width: 100%;

      .gmap_canvas {
        overflow: hidden;
        background: none !important;
        height: 500px;
        width: 100%;

        iframe {
          height: 500px;
          width: 100%;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  .about-extra {
    margin-bottom: 50px;
    @media #{$media_md} {
      margin-bottom: 120px;
    }

    h5 {
      position: relative;
      margin-bottom: 50px;

      img {
        display: block;
        width: 100%;
        height: 300px;
        z-index: 1;
        object-position: center center;
        object-fit: cover;
      }

      span {
        display: inline-block;
        padding: 26px 30px 21px 30px;
        background: $web_beige;
        width: 260px;
        color: $web_dark;
        position: absolute;
        bottom: -25px;
      }
    }

    p {
      padding-left: 30px;
      padding-right: 30px;
      color: $web_dark;
      margin-bottom: 50px;
      @media #{$media_md} {
        margin-bottom: 0;
      }
    }
  }

  .harmonogram {
    margin-bottom: 80px;
    @media #{$media_md} {
      margin-bottom: 160px;
    }

    h2 {
      color: $web_dark;
      margin-bottom: 55px;
      text-align: center;
      font-size: 0.7*$size_h2;
      line-height: 1.5*0.7*$size_h2;
      @media #{$media_md} {
        font-size: $size_h2;
        line-height: 1.5*$size_h2;
      }
    }

    .harmonogram-holder {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-flow: row wrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      @media #{$media_md} {
        flex-direction: row;
        align-content: initial;
        justify-content: initial;
        align-items: initial;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        left: 0;
        right: 0;
        background: $web_beige url('/img/background_harmonogram.svg') 100% 100% no-repeat;
        z-index: 1;
        @media #{$media_md} {
          width: 82%;
          left: 9%;
          right: 9%;
        }
      }

      .harmonogram-skolaudovane {
        flex-basis: 100%;
        margin-top: 2.5rem;
        margin-bottom: 1.5rem;

        @media #{$media_md} {
          margin-top: 0;
          margin-bottom: 0;
        }

        img {
          z-index: 1000;
          display: block;
          position: relative;
          margin: auto;

          @media #{$media_md} {
            top: 4rem;
          }
        }
      }

      .harmonogram-box {
        margin-top: 40px;
        margin-bottom: 40px;
        width: 75%;
        background: $white;
        box-shadow: 10px 10px 40px rgba($black, 0.08);
        position: relative;
        display: flex;
        align-content: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 30;
        @media #{$media_md} {
          width: 24%;
          margin-top: 150px;
          margin-bottom: 100px;
        }

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          left: 0;
          top: 57px;
          background: $white;
          z-index: 7;
          display: none;
        }

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          left: 0;
          top: 57px;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='1px25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2325406CFF' stroke-width='4' stroke-dasharray='4%2c14' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e");
          z-index: 5;
          display: none;
          @media #{$media_md} {
            display: block;
          }
        }

        &.active {
          margin-top: 70px;
          margin-bottom: 40px;
          width: 90%;
          @media #{$media_md} {
            margin-top: 70px;
            margin-bottom: 70px;
            width: 24%;
          }

          &:after {
            top: calc(50% + 2px);
          }

          &:before {
            top: calc(50% + 2px);
          }

          span {
            &.time {
              margin-top: 60px;
              @media #{$media_md} {
                margin-top: 23px;
              }
            }
          }
        }

        &.harmonogram-box-1 {
          margin-left: 0;
          margin-right: 0;
          @media #{$media_md} {
            margin-left: 0;
            margin-right: 1.3%;
          }

          &:before {
            display: block;
            width: 50%;
            left: 0;
          }
        }

        &.harmonogram-box-2 {
          margin-left: 0;
          margin-right: 0;
          @media #{$media_md} {
            margin-left: 0;
            margin-right: 1.3%;
          }
        }

        &.harmonogram-box-3 {
          margin-left: 0;
          margin-right: 0;
          @media #{$media_md} {
            margin-left: 0;
            margin-right: 1.3%;
          }
        }

        &.harmonogram-box-4 {
          margin-left: 0;
          margin-right: 0;
          @media #{$media_md} {
            margin-left: 0;
            margin-right: 0;
          }

          &:before {
            display: block;
            width: 50%;
            left: 50%;
          }
        }

        span {
          display: block;
          @include font();

          &.actual {
            color: $white;
            background: $web_blue;
            padding: 15px;
            font-size: $size_text_mini;
            line-height: 1.0*$size_text_mini;
            text-transform: uppercase;
            position: absolute;
            top: -21px;
          }

          &.time {
            background: $web_gold;
            padding: 15px;
            position: relative;
            margin-bottom: 10px;
            font-size: $size_text_small;
            line-height: 1.0*$size_text_small;
            margin-top: -27px;

            &:after {
              content: '';
              width: 14px;
              height: 14px;
              background: url('/img/ico_arrow_down_gold.svg') center center no-repeat;
              position: absolute;
              bottom: -10px;
              left: calc(50% - 7px);
            }
          }

          &.text {
            margin-bottom: 45px;
            font-size: $size_text_big;
            line-height: 1.3*$size_text_big;
          }
        }

        i {
          display: block;
          width: 48px;
          height: 48px;
          background: $white;
          position: relative;
          border-radius: 50%;
          z-index: 10;
          margin-top: 7px;
          margin-bottom: 7px;

          &:before {
            content: '';
            position: absolute;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            top: calc(50% - 14px);
            left: calc(50% - 14px);
            z-index: 20;
            background: $web_beige2;
          }

          &:after {
            content: '';
            position: absolute;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            top: calc(50% - 7px);
            left: calc(50% - 7px);
            z-index: 30;
            background: $web_gold;
          }
        }
      }
    }
  }

  .header-standard {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.0) 100%), url('/img/about.jpg');
    background-position: left center, bottom center;
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    margin-bottom: 60px;
    @media #{$media_md} {
      margin-bottom: 120px;
    }

    h1 {
      margin-top: 130px;
      margin-bottom: 80px;
      font-size: 0.7*$size_h1;
      line-height: 0.7*1.5*$size_h1;
      text-align: center;
      @media #{$media_md} {
        font-size: $size_h1;
        line-height: 1.5*$size_h1;
        margin-top: 210px;
        margin-bottom: 160px;
        text-align: left;
      }
    }
  }

  .standard-content {
    padding-bottom: 100px;

    h2 {
      color: $web_dark;
      margin-bottom: 50px;
    }

    h4 {
      margin-bottom: 25px;
      color: $web_blue;
    }

    p {
      margin-bottom: 70px;
      color: $web_dark;
    }

    h3 {
      color: $black
    }

    .renewable-resources {
      background-color: #f9f1e1;
      padding: 2rem 1rem;

      p {
        margin-bottom: 0;
      }
    }
  }

  .header-finance {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.0) 100%), url('/img/about.jpg');
    background-position: left center, bottom center;
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    margin-bottom: 60px;
    @media #{$media_md} {
      margin-bottom: 120px;
    }

    h1 {
      margin-top: 130px;
      margin-bottom: 80px;
      font-size: 0.7*$size_h1;
      line-height: 0.7*1.5*$size_h1;
      text-align: center;
      @media #{$media_md} {
        font-size: $size_h1;
        line-height: 1.5*$size_h1;
        margin-top: 210px;
        margin-bottom: 160px;
        text-align: left;
      }
    }
  }

  .finance-content {
    padding-bottom: 100px;

    h3 {
      margin-bottom: 15px;
      color: $web_gold;
    }

    h4 {
      margin-bottom: 25px;
      color: $web_blue;
      margin-top: 60px;
      position: relative;
      display: flex;
      align-content: center;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      @media #{$media_sm} {
        flex-direction: row;
      }

      span {
        display: inline-block;
        width: 40px;
        height: 40px;
        color: $white;
        background: $web_gold;
        font-size: $size_big;
        line-height: $size_big;
        margin-right: 20px;
        text-align: center;
        padding-top: 11px;
        flex-shrink: 0;
      }
    }

    p {
      margin-bottom: 15px;
      color: $web_dark;
      font-size: $size_text;
      line-height: 1.9*$size_text;

      &.hypo_text {
        background: $web_beige;
        padding: 15px;
        text-align: center;
        margin-top: 40px;
        @media #{$media_md} {
          padding: 30px;
        }
      }
    }

    .steps ul {
      -webkit-padding-start: 0;
      list-style: none;

      li {
        position: relative;
        padding-left: 16px;
        font-size: $size_text;
        line-height: 1.9*$size_text;
        @include font();

        &:before {
          content: '';
          background-color: $web_gold;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          position: absolute;
          top: 13px;
          left: 0;
        }
      }
    }

    .hypo_partners {
      text-align: center;

      img {
        display: inline-block;
        margin-bottom: 40px;
        height: 120px;
        max-width: 70%;
        object-fit: contain;

        @media #{$media_md} {
          margin-bottom: 0;
        }

      }
    }
  }
}

@media #{$media_md} {
  body .standard-content .renewable-resources {
    padding: 2rem 4rem;
  }
}

.sewell-finance {
  background-color: $web_beige;
  font-weight: 300;

  a {
    color: $web_dark
  }

  .bg-biege {
    background-color: $web_beige;
  }

  .bg-gold {
    background-color: $web_gold;
  }

  * {
    font-weight: 400
  }

  strong {
    font-weight: bold
  }

  .text-gold {
    color: $web_gold;
    font-weight: bold;
  }

  .uppercase {
    text-transform: uppercase;
  }
}

.z-10 {
  z-index: 10
}

.z-1 {
  z-index: 1
}

.h4-mobile {
  @media #{$media_max_md} {
    font-size: $size_h4;
    line-height: 1.5*$size_h4
  }
}

.btn-border {
  color: $web_blue;
  border-bottom: 2px solid $web_gold;
  padding: 0 0 25px;
  text-transform: uppercase;
  font-weight: bold;
  display: inline-block;

  &:hover {
    color: $web_gold;
  }
}

@media #{$media_max_md} {
  .text-mob-center {
    text-align: center
  }
}

.font-dark {
  color: $web_dark;
}

.btn {
  &-underlined {
    padding: .8rem .6rem;
    border-bottom: 3px solid $web_gold;
    transition: all 300ms;
    text-shadow: 2px 2px 2px #00000022;

    &:hover {
      color: white;
      background-color: $web_gold;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.p1-title {

  .p1-kolaudujeme {
    //font-size: 2rem;
    //padding: 0.3rem 1.1rem;
    //margin-bottom: 1.2rem;
    //display: inline-block;
    //background-color: #ff0000;
    //color: white;
    //font-weight: bold;

    height: 2.8rem;
    margin-bottom: 1.2rem;

    @media #{$media_sm} {
      //font-size: 3rem;
      //padding: 0.4rem 1.5rem;
      //margin-bottom: 1.4rem;

      height: 4.5rem;
      margin-bottom: 1.4rem;
    }

    @media #{$media_md} {
      //margin-bottom: initial;

      margin-bottom: initial;
    }
  }
}

.p1-title {
  position: relative;
  z-index: 5;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
  object-fit: cover;
  object-position: center center;
  display: none;
  min-height: 100%;

  &#video_desktop {

    @media (orientation: landscape) {
      display: block;
    }

  }

  &#video_mobile {

    @media (orientation: portrait) {
      display: block;
    }

  }

}
