@mixin border-radius($radius) {
    border-radius: $radius;
}

@mixin center-vertical() {
    position: absolute;
    top: 50%;

    transform: translateY(-50%);
}

@mixin center-horizontal() {
    position: absolute;
    left: 50%;

    transform: translateX(-50%);
}

@mixin center-perfect() {
    position: absolute;
    left: 50%;
    top:  50%;

    transform: translate(-50%, -50%);
}
@mixin center-reset() {
    position: inherit;
    left: inherit;
    top:  inherit;

    transform: none;
}

@mixin animacia($transition, $time) {
    transition: $transition $time;
}

@mixin animacia2($data) {
    transition: $data;
}

@mixin font() {
    font-family: "Inter", "sans-serif", "Arial";
    font-weight: 500;
}

@mixin fontRegular() {
    font-family: "Inter", "sans-serif", "Arial";
    font-weight: 400;
}

@mixin fontBold() {
    font-family: "Inter", "sans-serif", "Arial";
    font-weight: 700;
}


@mixin placeholder-color($color) {
    input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
       color: $color;
       @include font();
    }
    input:-moz-placeholder, textarea:-moz-placeholder { /* Firefox 18- */
       color: $color;
       @include font();
    }
    input::-moz-placeholder, textarea::-moz-placeholder {  /* Firefox 19+ */
       color: $color;
       @include font();
    }
    input:-ms-input-placeholder, textarea:-ms-input-placeholder {
       color: $color;
       @include font();
    }
}
