#gallery-page {
  * {
    font-family: Inter, sans-serif, Arial;
  }

  // header customization
  .page-header {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.0) 100%), url('/img/about_obchodne_priestory.png');
    background-position: left center, bottom center;
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    margin-bottom: 50px;

    h1 {
      margin-top: 130px;
      margin-bottom: 80px;
      font-size: 0.7*$size_h1;
      line-height: 0.7*1.5*$size_h1;
      text-align: center;
      @media #{$media_md} {
        font-size: $size_h1;
        line-height: 1.5*$size_h1;
        margin-top: 210px;
        margin-bottom: 160px;
        text-align: left;
      }
    }
  }

  // GALLERY BUTTONS

  .gallery-buttons {
    & button {
      font-size: 0.875rem;
      line-height: 1.3125rem;
      background-color: #fff;
      border: 1px solid #D9BB80;
      color: #d9bb80;
      text-transform: uppercase;
      position: relative;
      padding: 16px 44px;
      margin: 6px;
      display: inline-block;
      font-family: "Inter", sans-serif, Arial;
      font-weight: 700;

      &:hover {
        background-color: #d9bb80;
        color: #fff;
      }

      &.active {
        background-color: #d9bb80;
        color: #fff;

        &:before {
          background: url(/img/ico_mark.svg) 50% no-repeat;
          content: "";
          height: 22px;
          position: absolute;
          top: calc(50% - 9px);
          width: 22px;
          left: 8px;
        }
      }
    }
  }

  // GALLERY CARDS

  .card {
    img {
      object-fit: cover;
      aspect-ratio: 3/2;

      &:hover {
        cursor:zoom-in;
      }
    }
  }

  //.gallery-card-image-box {
  //  aspect-ratio: 4/3;
  //}

  .card-text {
    color:black;
  }


}

// GALLERY MODAL
#galleryModal {
  .modal-title {
    color:black;
  }

  .modal-body {
    padding:0;
  }
}


#galleryModalImage {
  object-fit: contain;
  width: 100%;
  max-height: 100%;
}

.p1-modal-arrow {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  &.p1-arrow-left, &.p1-arrow-right {

    div {
      background-position: 50%;
      background-repeat: no-repeat;
      width:60px;
      height: 60px;
      background-color: rgba(0,0,0,.9);
      cursor: pointer;
    }
  }

  &.p1-arrow-left {
    left: 0;

    div {
      background-image: url(/img/ico_arrow_left.svg);
    }
  }

  &.p1-arrow-right {
    right:0;

    div {
      background-image: url(/img/ico_arrow_right.svg);
    }
  }
}