#reservation-component {

  font-family: "Inter", sans-serif, Arial;
  font-weight: 500;
  background-color: #F9F1E1;

  .makler-container {
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;

    //@media #{$media_sm} {
    //  flex-direction: row;
    //  justify-content: start;
    //}

    //@media #{$media_md} {
    //  flex-direction: row;
    //  justify-content: center;
    //}

    @media #{$media_lg} {
      border-right: 2px solid #EED7AB;
    }

    .makler-component {

      .makler-img {
        //background-color: white;
        //border-radius: 50%;
        position: relative;
        align-self: center;
        display: block;
        margin: auto;

        @media #{$media_md} {
          align-self: auto;
        }

        img {
          max-width: 150px;
          display: inline-block;
          position: relative;
          margin-bottom: 20px;

          &:nth-of-type(1) {
            z-index: 2;
            margin-left: 10px;
          }
          &:nth-of-type(2) {
            z-index: 1;
            margin-left: -20px;
          }

          @media #{$media_md} {
            margin-bottom: 0;
          }
        }
      }

      .makler-info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        align-self: center;
        text-align: center;

        //@media #{$media_md} {
        //  align-self: auto;
        //  text-align: initial;
        //}

        .makler-info-text {
          display:inline-block;
          color:#25406C;
          font-size: 1.3rem;
          font-family: "Inter", sans-serif, Arial;
          font-weight: 500;
        }

        .makler-info-title {
          display:inline-block;
          text-transform: uppercase;
          color:#D9BB80;
          font-size:14px;
          font-family: "Inter", sans-serif, Arial;
          font-weight: bold;

        }
        .makler-info-name {
          display:inline-block;
          color:#25406C;
          font-size: 1.3rem;
          font-family: "Inter", sans-serif, Arial;
          font-weight: 500;

        }
      }

    }
  }

  .text-container {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @media #{$media_xl} {
      flex-direction: row;
      justify-content: initial;
    }


    .claim {
      color:#25406C;
      font-size: 1.6rem;
      font-family: "Inter", sans-serif, Arial;
      font-weight: 500;

      display: flex;
      flex-direction: column;
      justify-content: center;
      width:100%;
      height: 100%;

      text-align:center;

      @media #{$media_xl} {
        text-align:left;
      }
    }

    .reservation-button {
      font-size: 1.8rem;
      font-weight: bold;

      display: flex;
      flex-direction: column;
      justify-content: center;
      width:100%;
      height: 100%;

      a {
        //max-width:80%;
        padding: 28px 53px 28px 36px;
        position: relative;
        background: #D9BB80;
        color: #ffffff;
        border: 0;
        box-shadow: none;
        text-transform: uppercase;
        font-size: 0.875rem;
        font-family: "Inter", sans-serif, Arial;
        font-weight: 700;
        margin:auto;

        &:after {
          position: absolute;
          background: #C0A267 url(/img/ico_arrow_right.svg) center center no-repeat;
          right: 0;
          top: 0;
          width: 37px;
          height: 100%;
          content: "";
        }
      }
    }
  }



}