
h1 {
  color: $white;
  font-size: $size_h1;
  line-height: 1.5*$size_h1;
  @include font();
}

h2 {
  color: $white;
  font-size: $size_h2;
  line-height: 1.5*$size_h2;
  @include font();
}

h3 {
  color: $white;
  font-size: $size_h3;
  line-height: 1.5*$size_h3;
  @include font();
}

h4 {
  color: $white;
  font-size: $size_h4;
  line-height: 1.5*$size_h4;
  @include font();
}

h5 {
  color: $white;
  font-size: $size_h5;
  line-height: 1.5*$size_h5;
  @include font();
}

h6 {
  color: $web_blue;
  font-size: $size_h6;
  line-height: 1.5*$size_h6;
  @include font();
}

p {
  color: $white;
  font-size: $size_text;
  line-height: 1.5*$size_text;
  @include font();
}

.text-gold {
  color: $web_gold;
}

a.text-gold:hover {
  color: $web_gold2;
}

a {
  color: $white;
  font-size: $size_text;
  line-height: 1.5*$size_text;
  text-decoration: none;
  @include font();

  &:hover {
    text-decoration: none;
    color: $web_blue;
  }
}

.withGoldLine {
  position: relative;
  padding-left: 0;
  padding-top: 16px;
  display: inline-block;
  @include animacia(all, 0.3s);
  @media #{$media_md} {
    position: relative;
    padding-left: 45px;
    display: inline-block;
    padding-top: 0;
  }

  &:before {
    content: '';
    width: 26px;
    height: 2px;
    background-color: $web_gold;
    position: absolute;
    left: calc(50% - 13px);
    top: 0;
    @include animacia(all, 0.3s);
    @media #{$media_md} {
      content: '';
      width: 26px;
      height: 2px;
      background-color: $web_gold;
      position: absolute;
      left: 0;
      top: calc(50% - 1px);
    }
  }

  &:hover {
    &:before {
      width: 36px;
      left: calc(50% - 18px);
      @include animacia(all, 0.3s);
      @media #{$media_md} {
        left: 0;
      }
    }
  }
}

.withGoldUnderline {
  color: $white;
  text-transform: uppercase;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  width: 170px;
  white-space: nowrap;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-size: $size_text_small;
  line-height: 1.5*$size_text_small;
  margin-top: 0;
  @media #{$media_md} {
    padding-bottom: 25px;
    margin-left: inherit;
    margin-right: inherit;
    margin-top: 80px;
  }

  &:after {
    position: absolute;
    width: 100%;
    height: 2px;
    background: $web_gold;
    content: '';
    bottom: 0;
    left: 0;
    @include animacia(all, 0.3s);
  }

  &:hover {
    color: $white;

    &:after {
      width: 70%;
      left: 15%;
      @include animacia(all, 0.3s);
    }
  }

  img {
    display: inline-block;
    margin-right: 10px;
  }
}

.spacer {
  display: block;

  &.h50 {
    height: 50px;
  }

  &.h100 {
    height: 100px;
  }

  &.h100-md {
    @media #{$media_md} {
      height: 100px;
    }
  }

  &.h200 {
    height: 200px;
  }

  &.h300 {
    height: 300px;
  }
}

.inner {
  position: relative;
}

header {
  display: block;
  padding-top: 25px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 110;
  @media #{$media_md} {
    padding-top: 40px;
  }

  .menu-col {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      padding-left: 20px;
      @media #{$media_sm} {
        padding-left: 0;
      }

      img {
        width: 130px;
        @media #{$media_md} {
          width: 260px;
        }
      }
    }

    #toggle {
      position: relative;
      padding: 0 40px 0 0 !important;
      font-size: $size_menu_toggle;
      line-height: $size_menu_toggle;
      border: 0;
      border-radius: 0;
      background: none;
      cursor: pointer;
      height: 14px;
      margin-right: 20px;
      @media #{$media_sm} {
        margin-right: 0;
      }

      span {
        display: none;
        @media #{$media_md} {
          display: inline-block;
          color: $white;
          text-transform: uppercase;
        }
      }

      .patty {
        position: absolute;
        height: 14px;
        width: 28px;
        top: 0;
        right: 0;

        &:before {
          position: absolute;
          width: 28px;
          height: 2px;
          top: 1px;
          right: 0;
          background: $white;
          content: '';
        }

        &:after {
          width: 17px;
          height: 2px;
          bottom: 1px;
          right: 11px;
          background: $white;
          content: '';
          position: absolute;
          @include animacia(all, 0.3s);
        }
      }

      &:hover {
        .patty {
          &:after {
            width: 28px;
            right: 0;
            @include animacia(all, 0.3s);
          }
        }
      }
    }
  }

  &.scroll {
    position: fixed;
    width: 100%;
    top: 0;
    background-image: linear-gradient(180deg, rgba($web_dark, 0.5) 0%, rgba($web_dark, 0) 100%);
    background-position: right top;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 25px;
    padding-top: 25px;

    .menu-col {
      min-height: 50px;

      .gallery-categories {
        display: none;
      }

      #toggle {
        span {
          //text-shadow: 1px 2px 3px rgba($black, 0.5);
          //box-shadow: 1px 9px 17px rgba($white, 0.9);
          //color: $web_dark;
        }

        .patty {
          //box-shadow: 1px 9px 17px rgba($white, 0.9);
          &:before,
          &:after {
            //background-color: $web_dark;
          }
        }
      }
    }
  }
}

footer {
  background: $web_dark;
  padding-top: 50px;
  text-align: center;
  @media #{$media_md} {
    padding-top: 165px;
    text-align: left;
  }

  h6 {
    text-transform: uppercase;
    color: $web_gold;
    margin-bottom: 45px;
    padding-bottom: 0;
    font-size: $size_text;
    line-height: 1.5*$size_text;
    text-align: center;
    margin-top: 40px;
    @media #{$media_md} {
      text-align: left;
      font-size: $size_h6;
      line-height: 1.5*$size_h6;
      margin-top: 0;
    }
  }

  a {
    display: block;
    color: $white;
    margin-bottom: 20px;
    text-transform: uppercase;
    text-align: center;
    @include fontRegular();
    @include animacia(all, 0.3s);

    &:hover {
      color: $web_gold;
      padding-left: 10px;
      text-decoration: none;
      @include animacia(all, 0.3s);
    }

    @media #{$media_md} {
      //display: inline-block;
      text-align: left;
    }

    &.withGoldLine {
      font-size: 0.7*$size_contact_l;
      line-height: 0.7*1.3*$size_contact_l;
      margin-bottom: 4rem;
      text-transform: none;
      text-align: center;
      display: block;

      @media #{$media_md} {
        display: block;
        text-align: left;
        font-size: $size_contact_l;
      }

      &:hover {
        color: $web_gold;
        text-decoration: none;
        padding-left: 45px;
      }
    }

    &.social {
      display: inline-block;
      position: relative;
      background: $web_dark2;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      margin-right: 10px;
      margin-left: 10px;
      @include animacia(all, 0.3s);
      @media #{$media_md} {
        margin-left: 0;
        width: 54px;
        height: 54px;
      }

      &:hover {
        background: $web_gold;
        @include animacia(all, 0.3s);
      }

      &.fb {
        img {
          position: absolute;
          top: calc(50% - 10px);
          left: calc(50% - 7px);
        }
      }

      &.insta {
        img {
          position: absolute;
          top: calc(50% - 12px);
          left: calc(50% - 12px);
        }
      }
    }
  }

  .second {
    padding-bottom: 45px;
    padding-top: 30px;
    @media #{$media_md} {
      padding-top: 150px;
    }

    & > div {
      text-align: center;
      @media #{$media_md} {
        text-align: right;
      }

      &:first-of-type {
        text-align: center;
        @media #{$media_md} {
          text-align: left;
        }

        a {
          padding: 0;
          margin-bottom: 40px;
          @media #{$media_md} {
            margin-bottom: 0;
          }

          img {
            width: 200px;
            height: 50px;
            @media #{$media_md} {
              width: 160px;
              height: 30px;
            }
          }
        }
      }
    }

    a {
      color: $web_gray;
      font-size: $size_text_mini;
      line-height: 1.5*$size_text_mini;
      text-transform: none;
      margin-bottom: 0;
      display: block;
      padding: 7px 0;
      @media #{$media_md} {
        display: inline-block;
        padding: 6px 20px;
      }

      &:hover {
        color: $web_gold;
      }
    }

    p {
      color: $white;
      font-size: $size_text_mini;
      line-height: 1.5*$size_text_mini;
      margin-bottom: 20px;
      display: block;
      padding: 7px 0 7px 0;
      @media #{$media_md} {
        display: inline-block;
        margin-bottom: 0;
        padding: 6px 0 6px 20px;
      }
    }
  }
}

#navbar {
  ////
  // make navbar scrollable again
  overflow: scroll;
  height: 100vh !important;
  scrollbar-width: thin;
  ////

  width: 100%;
  background: $white;
  min-height: 100vh;
  z-index: 150;
  padding-top: 30px;
  position: fixed;
  display: none;
  @include animacia(all, 0.4s);
  @media #{$media_md} {
    width: 600px;
    position: fixed;
    height: 0;
    top: 0;
    right: -800px;
  }

  &.collapsing {
    height: auto;
    display: block;
    @include animacia(all, 0.4s);

    @media #{$media_md} {
      right: -600px;
    }
  }

  &.show {
    right: 0;
    height: auto;
    display: block;
    @include animacia(all, 0.4s);
  }

  .navbar-top {
    position: relative;
    margin-bottom: 50px;

    @media #{$media_md} {
      margin-bottom: 70px;
    }

    .close {
      position: absolute;
      width: 54px;
      height: 54px;
      background: $web_blue;
      right: 0;
      @media #{$media_md} {
        left: 0;
      }

      img {
        position: absolute;
        top: calc(50% - 12px);
        left: calc(50% - 12px);
      }
    }

    .logo {
      padding-left: 30px;
      display: block;
      padding-top: 3px;
      padding-bottom: 3px;
      height: 54px;
      @media #{$media_md} {
        padding-left: 115px;
      }

      img {
        width: 150px;
        height: 28px;
        margin-top: 13px;
        @media #{$media_md} {
          width: 260px;
          height: auto;
          margin-top: 0;
        }
      }
    }
  }

  .navbar-contact {
    margin-left: 0;
    margin-bottom: 30px;
    @media #{$media_md} {
      margin-left: 115px;
      margin-bottom: 70px;
    }

    a {
      margin-right: 0;
      display: block;
      text-align: center;
      margin-bottom: 20px;
      color: $web_blue;
      @media #{$media_md} {
        margin-right: 25px;
        display: inline-block;
        text-align: left;
        margin-bottom: 0;
      }
    }
  }

  .navbar-social {
    margin-left: 0;
    margin-bottom: 30px;
    text-align: center;
    @media #{$media_md} {
      margin-left: 115px;
      text-align: left;
    }

    a {
      display: inline-block;
      width: 40px;
      height: 40px;
      position: relative;
      background: $web_gold;
      border-radius: 50%;
      margin-right: 20px;
      margin-left: 20px;
      @media #{$media_md} {
        width: 54px;
        height: 54px;
        margin-left: 0;
      }

      &:hover {
        background: darken($web_gold, 15%);
        @include animacia(all, 0.3s);
      }

      &.fb {
        img {
          position: absolute;
          top: calc(50% - 10px);
          left: calc(50% - 7px);
        }
      }

      &.insta {
        img {
          position: absolute;
          top: calc(50% - 12px);
          left: calc(50% - 12px);
        }
      }
    }
  }

  nav {
    ul {
      margin-left: 0;
      margin-bottom: 50px;
      @media #{$media_md} {
        margin-left: 115px;
        margin-bottom: 70px;
      }

      .nav-item {
        text-align: center;
        @media #{$media_md} {
          text-align: left;
        }

        .nav-link {
          font-size: 0.8*$size_menu;
          line-height: 0.8*1.5*$size_menu;
          line-height: 1.7rem;
          color: $web_dark;
          padding: 0;
          margin-bottom: 10px;
          @include font();
          @include animacia(all, 0.3s);
          @media #{$media_md} {
            font-size: $size_menu;
            //line-height: 1.5*$size_menu;
            line-height: 1.7rem;
            margin-bottom: 30px;
          }

          &:hover {
            @include animacia(all, 0.5s);
            @media #{$media_md} {
              padding-left: 10px;
            }
          }
        }
      }
    }
  }
}

.slider-holder {
  .slider-inner {
    position: relative;
  }

  .carousel-arrow {
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.9);
    top: calc(50% - 30px);
    z-index: 10;
    @media #{$media_sm} {
      background-color: rgba(0, 0, 0, 0.6);
    }

    &.carousel-prev {
      background-image: url('/img/ico_arrow_left.svg');
      background-position: center center;
      background-repeat: no-repeat;
      left: 0;
    }

    &.carousel-next {
      background-image: url('/img/ico_arrow_right.svg');
      background-position: center center;
      background-repeat: no-repeat;
      right: 0;
    }

    &#list-type-carousel-prev,
    &#list-type-carousel-next {
      display: none !important;
      @media #{$media_xs} {
        display: block !important;
      }
    }

    &#detail-carousel-prev,
    &#detail-carousel-next {
      z-index: 101;
    }
  }

  .carousel {
    z-index: 8;
    @media #{$media_xs} {
      margin-left: calc((100% - 510px) / 2);
    }
    @media #{$media_sm} {
      margin-left: calc((100% - 690px) / 2);
    }
    @media #{$media_md} {
      margin-left: calc((100% - 930px) / 2);
    }
    @media #{$media_lg} {
      min-width: calc(100% - ((100% - 1110px) / 2));
      margin-left: calc((100% - 1110px) / 2);
    }
    @media #{$media_llg} {
      min-width: calc(100% - ((100% - 1290px) / 2));
      margin-left: calc((100% - 1290px) / 2);
    }

    .carousel-item {
      width: 100vw;
      margin-right: 25px;
      display: block;
      @media #{$media_xs} {
        width: 380px;
      }

      & > img {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        height: 305px;
        display: block;
        margin-bottom: 30px;
        object-fit: cover;
        object-position: center center;
        @media #{$media_xs} {
          width: 380px;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    .slick-dots {
      -webkit-padding-start: 0;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-wrap: nowrap;
      align-items: center;

      li {
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $web_gray2;
        margin: 5px;

        &.slick-active {
          background-color: $web_gold;
        }

        button {
          display: none;
        }
      }
    }
  }

  &.contact {
    margin-bottom: 60px;
    @media #{$media_md} {
      margin-bottom: 110px;
    }

    .carousel {
      .carousel-item {
        p {
          font-size: $size_big2;
          line-height: 1.5*$size_big2;
          color: $web_dark;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%;
          @media #{$media_xs} {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
          }

          span {
            color: $web_gold;
            padding-top: 12px;
            font-size: $size_text_small;
            line-height: $size_text_small;
            display: block;
            text-transform: uppercase;

            img {
              display: inline-block;
              position: relative;
              margin-right: 8px;
              top: -1px;
            }
          }
        }
      }
    }
  }

  &.list {
    margin-bottom: 30px;
    margin-left: 20px;
    @media #{$media_md} {
      margin-left: 0;
      margin-bottom: 50px;
    }

    .carousel-arrow {
      top: 20%;
      @media #{$media_xs} {
        top: calc(50% - 30px);
      }
    }

    .carousel {
      .carousel-item {
        width: 210px;
        margin-right: 20px;
        display: block;
        position: relative;
        padding-bottom: 400px;
        @media #{$media_xxs} {
          padding-right: 5rem;
          display: block !important;
          width: 210px !important;
        }
        @media #{$media_xs} {
          //width: 480px;
          padding-bottom: 175px;
          width: 100% !important;
        }

        img {
          width: 180px;
          height: 200px;
          margin-bottom: 0;
          z-index: 2;
          margin-left: 0;
          margin-right: 0;
          @media #{$media_xs} {
            width: 380px;
            height: 420px;
          }
        }

        .flat-type {
          font-size: $size_h2;
          line-height: $size_h2;
          color: $web_dark;
          text-transform: uppercase;
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          margin-bottom: 30px;
          z-index: 2;

          span {
            color: $web_blue;
            font-size: $size_text_small;
            line-height: $size_text_small;
            display: inline-block;
            position: relative;
            padding-bottom: 21px;
            width: 60px;
            @include fontBold();

            &:after {
              content: '';
              width: 46px;
              height: 2px;
              background: $web_gold;
              position: absolute;
              bottom: 6px;
              left: 0;
            }
          }
        }

        & > div {
          background: $white;
          display: flex;
          flex-direction: column;
          z-index: 10;
          position: absolute;
          box-shadow: 0 10px 20px rgba($black, 0.1);
          bottom: 30px;
          width: 190px;
          @media #{$media_xxs} {
            left: 1rem;
          }
          @media #{$media_xs} {
            bottom: 60px;
            width: 400px;
            min-height: 230px;
            flex-direction: row;
            box-shadow: 0 20px 30px rgba($black, 0.1);
            left: 4rem;
          }

          .flat-details {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: center;
            @media #{$media_xs} {
              text-align: left;
              width: 220px;
              height: 230px;
            }

            .flat-area {
              position: relative;
              margin-top: 20px;
              margin-bottom: 15px;
              color: $web_dark;
              padding: 5px 20px;

              &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                background-color: $web_gold;
                height: 100%;
                width: 4px;
              }

              & > span {
                display: flex;
                justify-content: space-between;
                font-size: $size_text_small;
                line-height: 1.5*$size_text_small;

                &:first-of-type {
                  margin-bottom: 10px;
                }

                strong {
                  text-align: left;
                }
              }
            }

            .price {
              font-size: $size_text_big2;
              line-height: 1.5*$size_text_big2;
              color: $web_blue;
              padding: 0 20px;
              margin-top: 15px;
              margin-bottom: 20px;
              text-align: left;
              @include fontRegular();
              @media #{$media_xs} {
                margin-bottom: 30px;
                font-size: $size_h3;
                line-height: 1.5*$size_h3;
                @include font();
              }

              span {
                color: $web_gold;
                font-size: $size_text_mini;
                line-height: 1.5*$size_text_mini;
                display: block;
                @include fontBold();
              }
            }
          }

          .floor-plan {
            height: 230px;
            width: 100%;
            border-top: 1px solid $web_gray4;
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            @media #{$media_xs} {
              border-left: 1px solid $web_gray4;
              border-top: 0;
              width: 180px;
            }

            img {
              max-height: 210px;
              max-width: 100%;
              object-fit: contain;
              margin-right: 0;
              object-position: center center;
              @media #{$media_xs} {
                max-height: 210px;
                max-width: 160px;
              }
            }

            svg {
              max-height: 210px;
              max-width: 100%;
              object-fit: contain;
              margin-right: 0;
              object-position: center center;
              @media #{$media_xs} {
                max-height: 210px;
                max-width: 160px;
              }

              g[data-title] {
                cursor: pointer;
                opacity: 0.0;
                fill: $web_gold;
                /*&:hover {
    opacity: 0.8;
}*/
              }
            }
          }
        }
      }
    }

    .slick-dots {
      display: flex;
      @media #{$media_lg} {
        display: none !important;
      }
    }
  }

  &.detail {
    z-index: 1;

    .slider-inner {
      .carousel {
        height: $detail_header_height;
        margin-left: 0;

        &:after {
          background-image: linear-gradient(90deg, rgba($black, 0.5) 0%, rgba($black, 0.1) 100%);
          background-position: left center;
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          content: '';
        }

        .carousel-item {
          width: 100%;
          margin-right: 0;

          img {
            height: $detail_header_height;
            width: 100% !important;
            object-fit: cover;
            object-position: center center;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 0;
          }
        }

        .slick-dots {
          display: none !important;
        }
      }
    }
  }

  &.gallery-big {
    width: 100vw;
    border-bottom: 1px solid $web_gold;
    //height: 100vh;
    .carousel-arrow {
      &.carousel-prev {
        left: 0;
      }

      &.carousel-next {
        right: 0;
      }
    }

    .carousel {
      z-index: 8;
      margin-left: 0;

      .slick-list {
        //height: 100vh;
        .slick-track {
          //height: 100vh;
        }
      }

      .carousel-item {
        width: 100vw;
        margin-right: 0;
        height: 100%;
        min-height: 100vh;
        background: $web_dark;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        img {
          width: 100vw;
          height: 100vh;
          object-fit: cover;
          object-position: center center;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 0;
        }

        @media #{$media_md} {
        }
        @media #{$media_xl} {
        }
      }

      .slick-dots {
        display: none;
      }
    }
  }

  &.gallery-small {
    position: absolute;
    bottom: 15px;
    z-index: 20;
    height: 90px;
    width: calc(100%);
    display: none;
    @media #{$media_xs} {
      display: block;
    }
    @media #{$media_md} {
      bottom: 35px;
    }
    /*
        @media #{$media_xs} {
            max-width: 540px;
            margin-left: calc((100% - 510px) / 2);
        }

        @media #{$media_sm} {
            max-width: 720px;
            margin-left: calc((100% - 690px) / 2);
        }

        @media #{$media_md} {
            max-width: 960px;
            margin-left: calc((100% - 930px) / 2);
        }

        @media #{$media_lg} {
            max-width: 1080px;
            margin-left: calc((100% - 1110px) / 2);
        }

        @media #{$media_llg} {
            max-width: 1320px;
            margin-left: calc((100% - 1290px) / 2);
        }*/
    .carousel-arrow {
      width: 30px;
      height: 30px;
      background-color: rgba(0, 0, 0, 0.9);
      top: calc(50% - 15px);
      z-index: 10;
      @media #{$media_sm} {
        background-color: rgba(0, 0, 0, 0.6);
      }

      &.carousel-prev {
        left: 0;
      }

      &.carousel-next {
        right: 0;
      }
    }

    .carousel {
      z-index: 8;
      min-width: auto !important;
      max-width: 100%;
      width: calc(100% - 60px);
      margin-left: 30px;
      /*
            @media #{$media_xs} {
                max-width: 540px;
                margin-left: calc((100% - 510px) / 2);
            }

            @media #{$media_sm} {
                max-width: 720px;
                margin-left: calc((100% - 690px) / 2);
            }

            @media #{$media_md} {
                max-width: 960px;
                margin-left: calc((100% - 930px) / 2);
            }

            @media #{$media_lg} {
                max-width: 1080px;
                margin-left: calc((100% - 1080px) / 2);
            }

            @media #{$media_llg} {
                max-width: 1320px;
                margin-left: calc((100% - 1290px) / 2);
            }*/
      .carousel-item {
        width: 120px !important;
        height: 76px;
        background: $white;
        object-fit: cover;
        object-position: center center;
        padding: 6px 10px 6px 10px;
        margin-right: 0;
        cursor: pointer;
      }

      .slick-dots {
        display: none;
      }
    }
  }
}

.dropdown {
  //width: calc(100% - 40px);
  display: inline-block;
  position: relative;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 15px;
  @media #{$media_sm} {
    //width: 110px;
    margin-left: 0;
    margin-right: 15px;
    margin-bottom: 0;
  }
  @media #{$media_md} {
    //width: 150px;
    margin-left: 20px;
    margin-right: 0;
  }

  button {
    width: 100%;
    text-align: left;
    border-bottom: 1px solid $web_gray4;
    padding-left: 0;
    padding-right: 2rem;
    text-transform: uppercase;
    font-size: $size_text_small;
    line-height: 1.5*$size_text_small;
    position: relative;
    padding-bottom: 11px;
    @include font();

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:hover {
      border-bottom: 1px solid $web_gold;
    }

    &:after {
      right: 4px;
      position: absolute;
      top: 10px;
      background: url('/img/ico_gold_arrow_down_small.svg') center center no-repeat;
      border: 0;
      content: '';
      display: block;
      margin-left: 0;
      width: 10px;
      height: 10px;
    }

    &.show {
      &:after {
        background: url('/img/ico_gold_arrow_up_small.svg') center center no-repeat;
        top: 14px;
      }
    }
  }

  .dropdown-menu {
    width: 100%;
    min-width: auto;
    border: 0;
    border-radius: 0;
    background: $white;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
    @media #{$media_sm} {
      width: auto;
      min-width: 110px;
    }
    @media #{$media_md} {
      width: 150px;
      min-width: auto;
    }

    &.show {
      transform: translate(0px, 40px) !important;
      @media #{$media_sm} {
        transform: translate(0px, 35px) !important;
      }
    }

    li {
      cursor: pointer;

      &:nth-child(odd) {
        background: rgba($black, 0.02);
      }

      .dropdown-item {
        color: $web_dark;
        white-space: normal;
        @include font();

        &:hover {
          color: $web_gold;
          background: $white;
        }
      }
    }
  }
}

.circles {
  text-align: center;
  @media #{$media_md} {
    margin-top: 0;
  }

  .info {
    position: relative;
    display: block;
    width: 120px;
    height: 120px;
    transform: scale(.5);
    margin: -30px auto -30px -30px;
    @media #{$media_xxxs} {
      transform: scale(.55);
    }
    @media #{$media_xxs} {
      //transform: scale(.67);
      transform: scale(.85);
      margin-left: -20px;
      margin-bottom: 0;
      margin-top: -8px
    }
    @media #{$media_sm} {
      margin-left: auto;
    }
    @media #{$media_md} {
      margin-bottom: 0;
      margin-left: -15px;
      //transform: scale(.85);
      transform: scale(1);
    }
    @media #{$media_llg} {
      //transform: scale(1);
      transform: scale(1.3);

    }

    &:hover {
      span.hover-eff {
        text-decoration: underline;
      }
    }

    p {
      position: absolute;
      text-align: center;
      z-index: 10;
      width: 120px;
      height: 68px;
      top: 26px;
      margin-bottom: 0;
      font-size: $size_info;
      line-height: 1.3*$size_info;
      display: block;
      color: $web_gold;

      .progress-ring__free {
        font-size: 0.9*$size_info;
        line-height: 1.1*$size_info;
        font-weight: 300;
        display: block;
        color: $web_gold;
        @media #{$media_md} {
          font-size: 1.0*$size_info;
          line-height: 1.2*$size_info;
        }
      }

      span {
        font-size: 1.6*$size_text_mini;
        line-height: 1.6*$size_text_mini;
        color: $white;
        display: block;
        @media #{$media_md} {
          font-size: 1.4*$size_text_mini;
          line-height: 1.4*$size_text_mini;
        }
      }
    }

    .progress-ring {
      width: 120px;
      height: 120px;
      background: rgba($black, 0.35);
      border-radius: 50%;
      z-index: 1;

      .progress-ring__circle {
        stroke-dasharray: 364;
        stroke-dashoffset: 0;
        stroke: $white;
        transition-property: stroke-dashoffset;
        transition-duration: 4s;
        transform: rotate(-90deg);
        transform-origin: 50% 50%
      }
    }
  }
}

form {
  display: block;
  background: $white;
  width: 100%;
  position: relative;
  padding: 85px 30px 30px 30px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.1);

  .text-error {
    color: #EC7171;
  }

  .font-small {
    font-size: .75rem;
  }

  @media #{$media_md} {
    width: calc(100% - 30px);
  }

  &:before {
    content: '';
    width: 56px;
    height: 56px;
    background: $web_blue url('/img/ico_mail.svg') center center no-repeat;
    position: absolute;
    top: 0;
    left: calc(50% - 28px);
  }

  h4 {
    color: $web_dark;
    text-align: center;
    margin-bottom: 30px;
  }

  h6 {
    color: $web_gold;
    text-align: center;
    margin-bottom: 30px;
  }

  .form-group {
    position: relative;
    padding-top: 20px;
    margin-bottom: 20px;

    label {
      position: absolute;
      color: $web_gray;
      font-size: $size_text_small;
      top: 44px;
      left: 17px;
      @include animacia(all,
              0.4s);

      &.noAnimation {
        top: 0 !important;
        left: 0 !important;
        font-size: $size_text_mini !important;
      }
    }

    .form-control {
      padding: 23px 16px;
      border-radius: 0;
      border: 1px solid $web_gray2;
      font-size: $size_text_small;
      resize: none;
      color: $web_dark;

      &:focus {
        outline: none;
        box-shadow: none;
        border: 1px solid $web_gold;

        & + label {
          top: 0;
          left: 0;
          color: $web_gold;
          font-size: $size_text_mini;
          @include animacia(all, 0.4s);
        }
      }

      &:hover {
        border: 1px solid $web_gold;
      }
    }

    small {
      font-size: $size_text_mini;
      line-height: $size_text_mini;
      position: absolute;
      color: $web_gold !important;
      top: 0;
      right: 0;

      &.text-error {
        display: none;
        left: 0;
        top: 88px;
      }
    }

    &.error {
      label {
        color: $web_red !important;
      }

      .form-control {
        border: 1px solid $web_red !important;
        color: $web_red !important;
      }

      small {
        color: $web_red !important;

        &.text-error {
          display: block;
        }
      }
    }
  }

  .form-check {
    margin-top: 20px;
    color: $web_gray;
    font-size: $size_text_small;
    line-height: $size_text_small;

    a {
      color: $web_gold;
      font-weight: 400;
      text-decoration: underline;
      font-size: $size_text_small;
      line-height: $size_text_small;

      &:hover {
        text-decoration: none;
      }
    }

    .form-check-input {
      &[type="checkbox"] {
        border-radius: 0;
        border: 1px solid $web_gray2;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        position: relative;
        top: -6px;
        cursor: pointer;

        &:focus {
          outline: none;
          box-shadow: none;
          border: 1px solid $web_gray2;
        }

        &:hover {
          border: 1px solid $web_gold;
        }

        &:checked {
          background-color: $web_gold !important;
          border: 1px solid $web_gold !important;
        }
      }
    }

    &.error {
      .form-check-input {
        &[type="checkbox"] {
          border: 1px solid $web_red;
          background-color: $web_red;
        }
      }
    }
  }

  .button-row {
    margin-top: 25px;

    button {
      padding: 28px 53px 28px 36px;
      position: relative;
      background: $web_gold;
      color: $white;
      border: 0;
      box-shadow: none;
      text-transform: uppercase;
      font-size: $size_text_small;
      line-height: $size_text_small;
      @include fontBold();

      &:after {
        position: absolute;
        background: $web_gold2 url('/img/ico_arrow_right.svg') center center no-repeat;
        right: 0;
        top: 0;
        width: 37px;
        height: 100%;
        content: '';
      }

      &:hover {
        background: $web_gold2;
      }
    }
  }

  p {
    &.form-result-text {
      text-align: center !important;
      font-size: $size_text_small;
      line-height: 1.5*$size_text_small;
      margin-bottom: 0 !important;
      margin-top: 20px;
      padding: 15px 40px;
      @include font();

      &.form-result-text-error {
        color: $web_red;
        background: $web_red2;
      }

      &.form-result-text-send {
        color: $web_blue;
        background: $web_blue2;
      }
    }
  }
}

.hide {
  display: none;
}

#cookie {
  position: fixed;
  width: 100%;
  background: rgba($black, 0.8);
  padding-top: 40px;
  padding-bottom: 20px;
  text-align: center;
  bottom: 0;
  z-index: 10000;

  &.hide {
    display: none;
  }

  p {
    color: $white;
    font-size: $size_text_small;
    line-height: 1.6*$size_text_small;
    @include font();

    a {
      color: $web_gold;
      text-decoration: underline;
      font-size: $size_text_small;
      line-height: 1.6*$size_text_small;
      @include font();
    }
  }

  .cookie-button {
    background: $web_gray;
    border-radius: 5px;
    padding: 5px 20px;
    display: inline-block;
    color: white;
    border: 1px solid $web_gray;
    font-size: $size_text_small;
    line-height: 1.6*$size_text_small;
    @include animacia(all,
            0.4s);

    &:hover {
      text-decoration: none;
      background: $web_dark;
      @include animacia(all,
              0.4s);
    }
  }
}

.map-holder {
  #map_about {
    height: 530px;
    width: 100%;
    @media #{$media_md} {
      width: 95%;
    }
  }
}

.menu-with-language-switcher {
  display: flex;
  align-items: baseline;

  .language-switcher {
    margin-right: 2rem;
    justify-self: end;

    a {

      margin-right: .5rem;
      text-transform: uppercase;

      &.active {
        text-decoration-line: underline;
        text-decoration-color: #D9BB80;
        text-decoration-thickness: 3px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
