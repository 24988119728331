@keyframes HideList {
    from {
        transform: scaleY(1);
    }
    to {
        transform: scaleY(0);
    }
}

@keyframes moveArrowRight {
    0% {
        right: 0;
    }
    100% {
        right: -40px;
    }
}

@keyframes moveArrowLeft {
    0% {
        left: 0;
    }
    100% {
        left: -40px;
    }
}