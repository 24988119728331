.featured-card {

  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  display:flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 2rem 2rem 3rem 2rem;

  .featured-card-icon {
    flex-grow: 0;
    height: 50px;

  }

  .featured-card-header {
    flex-grow: 0;

    h3 {
      color:#1F1F1F;
      font-size: 1.5rem;
      line-height: 1.3;
      font-family: Inter, sans-serif, Arial;
      font-weight: 500;
      padding: 1.5rem 0rem 1rem 0rem;
    }
  }

  .featured-card-content {
    flex-grow: 1;
  }

  .featured-card-button {
    position:relative;
    top:3rem; // same as .featured-card padding-top!
    left:2rem; // same as .featured-card padding-left!
    line-height: 37px; // same as a:after height!

    a {
      display: inline-block;
      color: #E7E7E7;

      &:after {
        position: absolute;
        background: #25406C url(/img/ico_arrow_right.svg) center center no-repeat;
        right: 0;
        top: 0;
        width: 37px;
        height: 37px;
        content: "";
      }
    }
  }
}