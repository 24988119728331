#energy-efficient-housing-page {

  // header customization
  .page-header {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.0) 100%), url('/img/energia_header.png');
    background-position: left center, bottom center;
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    margin-bottom: 150px;

    h1 {
      margin-top: 130px;
      margin-bottom: 80px;
      font-size: 0.7*$size_h1;
      line-height: 0.7*1.5*$size_h1;
      text-align: center;
      @media #{$media_md} {
        font-size: $size_h1;
        line-height: 1.5*$size_h1;
        margin-top: 210px;
        margin-bottom: 160px;
        text-align: left;
      }
    }
  }

  // custom styles




}