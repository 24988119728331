// Fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
@import 'variables';
@import 'functions';
@import 'animations';
@import 'base';
@import '~lightbox2/src/css/lightbox.css';
@import 'main';
@import "free-parking";
@import "cookiesAlert";
// components
@import "_reservation";
@import "_featuredCard";
@import "_infoCard";
@import "_customCarousel";
// pages
@import "_energyEfficientHousing";
@import "_standard";
@import "_investment";
@import "_galleryPage";
